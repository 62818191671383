import { AccountInfo, Configuration, EndSessionRequest, EventType, LogLevel, PopupRequest, PublicClientApplication, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../RAFComponents/helpers/AppHelper";
import { StorageKey } from "../constants/Common/Constants";
import { getQueryString, getSubDomain, hasSSO, isNotNullAndUndefined, IsNotNullOrWhiteSpace, IsNullOrWhiteSpace } from "../RAFComponents/helpers/utils";
import { TenantRow } from "../RAFComponents/models/Common/TenantRow";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { getAWSToken } from "../services/getAWSToken";
//import { UIManager } from "./UIManager";
//import { SsoSilentRequest } from "@azure/msal-browser/dist/src/request/SsoSilentRequest";
//import * as R from "ramda";

export const AzureAdB2C = {
    // "Instance": "https://loginworkesio.b2clogin.com",
    // "Domain": "loginworkesio.onmicrosoft.com",
    // "AuthorityDomain": "loginworkesio.b2clogin.com",

    "Instance": "https://login.workesio.com",
    "Domain": "7e498c80-97e5-499b-8340-1a9a18fa5534",
    "AuthorityDomain": "login.workesio.com",

    "ClientId": "7bb8bdd4-fc73-4774-9260-3189bfa4f2dc",
    "DefaultSignUpPolicyId": "B2C_1_default_signup",
    // "DefaultSignInPolicyId": "B2C_1_default_signin",
    "DefaultSignInPolicyId": "B2C_1A_SIGNUP_SIGNIN_MS",
    "PhoneSignInPolicyId": "b2c_1a_ph_susi",


    "AcquireTokenScope": "https://loginworkesio.onmicrosoft.com/9af4cf8f-1634-4fa6-a0ca-d4a61d9836e9/access_as_interactive_user"
    //"AcquireTokenScope": "https://login.workesio.com/9af4cf8f-1634-4fa6-a0ca-d4a61d9836e9/access_as_interactive_user"
};

const redirectUri = '/authsuccess';// IsNullOrWhiteSpace(process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE) ? "https://app.beta.workesio.com/authsuccess" : process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE;
export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: AzureAdB2C.ClientId,
        authority: `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId}`,// Choose SUSI as your default authority.
        knownAuthorities: [AzureAdB2C.AuthorityDomain, AzureAdB2C.DefaultSignInPolicyId],
        redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        //authorityMetadata: '{"token_endpoint": "https://loginworkesio.b2clogin.com/loginworkesio.onmicrosoft.com/b2c_1_default_signin/oauth2/v2.0/token"}'
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
        iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
        loadFrameTimeout: 9000, // Applies to both silent and popup calls - In milliseconds
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                }
            }
        }
    }
};

export enum SkytrustLoginEventType {
    BU_START = "msal:buStart",
    BU_SUCCESS = "msal:buSuccess",
    BU_FAILURE = "msal:buFailure",
    BU_DISABLED = "msal:buDisabled",
    ACCESSTOKEN_START = "msal:accessTokenStart",
    ACCESSTOKEN_SUCCESS = "msal:accessTokenSuccess",
    ACCESSTOKEN_FAILURE = "msal:accessTokenFailure",
    ACTIVATE_SUCCESS = "msal:activateSuccess",
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export let loginRequest: RedirectRequest = {
    // scopes: [AzureAdB2C.AcquireTokenScope,
    //     //...protectedResources.apiTodoList.scopes.write
    // ],
    scopes: [AzureAdB2C.AcquireTokenScope],
    //scopes: [],

};

export class RAFAuthModule extends PublicClientApplication {
    private signUpRedirectRequest: RedirectRequest;
    //private loginRequest: PopupRequest;
    public acquireTokenSilentRequest: SilentRequest;

    // constructor(configuration: Configuration) {
    //     super(configuration);

    //     // loginRequest = {
    //     //     scopes: []
    //     // };

    //     //const redirectUri = "/";
    //     //const redirectUri = "https://localhost:3000/authsuccess";
    //     const redirectUri = IsNullOrWhiteSpace(process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE) ? "https://beta.secure.skytrust.co/authsuccess" : process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE;

    //     loginRedirectRequest = {
    //         ...loginRequest,
    //         //redirectStartPage: window.location.href,
    //         redirectUri: redirectUri,
    //         //domainHint: window.location.hostname,
    //         //extraQueryParameters: { login_hint: "jagan@radamtech.com" },
    //         //extraQueryParameters: { local_login: "false" },
    //     };

    //     // loginRequest = {
    //     //     ...loginRequest,
    //     //     redirectUri: redirectUri,
    //     // };

    //     this.signUpRedirectRequest = {
    //         ...loginRequest,
    //         //redirectStartPage: window.location.href,
    //         redirectStartPage: "/",
    //         redirectUri: redirectUri,
    //         //loginHint: "satheesh@radamtech.com",
    //         //domainHint: window.location.origin,
    //         //extraQueryParameters: { login_hint: "satheesh@radamtech.com" },
    //     };

    //     this.acquireTokenSilentRequest = {
    //         //scopes: [AzureAdB2C.AcquireTokenScope]
    //         scopes: appConfig.b2cAcquireTokenScope
    //     };


    //     this.setAuthority();
    // }


    getUserName(): string | null {
        const account = this.getActiveAccount();
        if (isNotNullAndUndefined(account)) {
            return account.name;
        }
        return null;
    }

    getRootBusinessUnitId(): string | null {
        const account = this.getActiveAccount();
        if (isNotNullAndUndefined(account)) {
            const idTokenClaims = account.idTokenClaims;
            if (isNotNullAndUndefined(idTokenClaims)) {
                return idTokenClaims["businessUnitUID"] as string;
            }
        }
        return null;
    }

    // get isAuthenticated(): boolean {
    //     const account = this.getActiveAccount();
    //     if (isNotNullAndUndefined(account)) {
    //         return true;
    //     }
    //     return false;
    // }



    logOutAD(): void {
        const account: AccountInfo = this.getActiveAccount();
        if (account) {
            const logOutRequest: EndSessionRequest = {
                account,
                postLogoutRedirectUri: "/"
            };
            this.logoutRedirect(logOutRequest);
        }
    }

    async getADToken() {
        const accessToken1 = await getAWSToken();
        if (IsNotNullOrWhiteSpace(accessToken1)) {
            this._accessToken = accessToken1;
            return accessToken1;
        }
        else {
            const account = this.getActiveAccount();
            const response = await this.acquireTokenSilent({
                scopes: [AzureAdB2C.AcquireTokenScope],
                //scopes: appConfig.b2cAcquireTokenScope,
                account: account
            });
            this._accessToken = response.accessToken;
            return response.accessToken;
        }
    }

    // signUpAD(loginHint?: string, policyIdSuffix?: string): void {

    //     if (isNotNullAndUndefined(loginHint)) {
    //         this.signUpRedirectRequest.loginHint = loginHint;
    //     }
    //     let authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignUpPolicyId}`;
    //     if (!IsNullOrWhiteSpace(policyIdSuffix)) {
    //         authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignUpPolicyId.replace("_default_", `_${policyIdSuffix}_`)}`;
    //     }

    //     this.signUpRedirectRequest.authority = authority;
    //     this.loginRedirect(this.signUpRedirectRequest);
    // }

    // get currentBusinessUnitId(): string {
    //     return isNotNullAndUndefined(this.currentBusinessUnit) ? this.currentBusinessUnit.UID : null;
    // }

    // get currentBusinessUnitName(): string {
    //     return isNotNullAndUndefined(this.currentBusinessUnit) ? this.currentBusinessUnit.BusinessUnitName : null;
    // }
    get currentBusinessUnitId(): string {
        const recentClickedWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));

        if (isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.PortalName)) {
            return isNotNullAndUndefined(recentClickedWorkspace.UID) ? recentClickedWorkspace.UID : null;
        } else {
            return isNotNullAndUndefined(this.currentUser) ? this.currentUser.CurrentPortalUID : null;
        }
    }

    get currentBusinessUnitName(): string {
        const recentClickedWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));
        if (isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.PortalName)) {
            return isNotNullAndUndefined(recentClickedWorkspace.UID) ? recentClickedWorkspace.PortalName : null;
        } else {
            return isNotNullAndUndefined(this.currentUser) ? this.currentUser.CurrentPortal : null;
        }
    }

    get currentBusinessUnit(): { UID: string, BusinessUnitName: string; } {
        let retVal: { UID: string, BusinessUnitName: string; } = null;
        const objStoarge1 = getSessionStorage(StorageKey.currentBusinessUnit);
        if (!IsNullOrWhiteSpace(objStoarge1)) {
            retVal = JSON.parse(objStoarge1);
        }
        return retVal;
    }

    set currentBusinessUnit(value: { UID: string, BusinessUnitName: string; }) {
        isNotNullAndUndefined(value) ? setSessionStorage(StorageKey.currentBusinessUnit, false, JSON.stringify(value)) : removeSessionStorage(StorageKey.currentBusinessUnit, false);
    }

    get currentUserId(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.UserUID : null;
    }

    get currentUserName(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.DisplayName : null;
    }

    get currentTenantId(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.TenantUID : null;
    }

    get currentTenantName(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.TenantName : null;
    }

    get currentTenants(): TenantRow[] {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.Tenants : null;
    }

    get currentUserEmail(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.Email : null;
    }

    // get currentPortalUID(): string {
    //     return isNotNullAndUndefined(this.currentUser) ? this.currentUser.PortalUID : null;
    // }

    // get currentPortal(): string {
    //     return isNotNullAndUndefined(this.currentUser) ? this.currentUser.Portal : null;
    // }

    //get currentUser(): { UserUID: string, DisplayName: string, Email: string  } {
    get currentUser(): UserInfoRow {
        //let retVal: { UserUID: string, DisplayName: string, Email: string, TenantUID: string, Tenants: TenantRow[], TenantName: string } = null;
        let retVal: UserInfoRow = null;
        const objStoarge1 = getSessionStorage(StorageKey.currentUser);
        if (!IsNullOrWhiteSpace(objStoarge1)) {
            retVal = JSON.parse(objStoarge1);
        }
        return retVal;
    }

    //set currentUser(value: { UserUID: string, DisplayName: string, Email: string }) {
    set currentUser(value: UserInfoRow) {
        isNotNullAndUndefined(value) ? setSessionStorage(StorageKey.currentUser, false, JSON.stringify(value)) : removeSessionStorage(StorageKey.currentUser, false);
    }





    private _accessToken: string = null;

    get accessToken(): string {
        return this._accessToken;
    }

    set accessToken(objToken: string) {
        this._accessToken = objToken;
    }

    private _error: string = null;

    get error(): string {
        return this._error;
    }

    set error(eventType: string) {
        this._error = eventType;
    }

    private _currentEventType: EventType = null;

    get currentEventType(): EventType {
        return this._currentEventType;
    }

    set currentEventType(eventType: EventType) {
        this._currentEventType = eventType;
    }

    public onSkytrustLoginEventTypeChange: (val: SkytrustLoginEventType) => void;
}