import { useMsal } from "@azure/msal-react";
import {
  Ajax,
  Browser,
  closest,
  createElement,
  debounce,
  getComponent,
} from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { DatePicker, DateRangePicker } from "@syncfusion/ej2-react-calendars";
import {
  ChangeEventArgs,
  ComboBox,
  DropDownList,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  // ColumnModel,
  ColumnsDirective,
  // CommandModel,
  // DataStateChangeEventArgs,
  Edit,
  ExcelExport,
  //ExcelExportProperties,
  Filter,
  FilterSettingsModel,
  //FilterSettingsModel,
  Freeze,
  Grid,
  // Grid,
  GridComponent,
  //GridModel,
  Group,
  GroupSettingsModel,
  //GroupSettingsModel,
  //IFilterMUI,
  //IFilterUI,
  Inject,
  Page,
  PageSettingsModel,
  //PageSettingsModel,
  PdfExport,
  PdfHeaderQueryCellInfoEventArgs,
  PdfQueryCellInfoEventArgs,
  PredicateModel,
  RecordClickEventArgs,
  // PdfExportProperties,
  //PdfHeaderQueryCellInfoEventArgs,
  //PdfQueryCellInfoEventArgs,
  //PredicateModel,
  //RecordClickEventArgs,
  Resize,
  SelectionSettingsModel,
  // SelectionSettingsModel,
  Sort,
  SortSettingsModel,
  //SortSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { TextBox } from "@syncfusion/ej2-react-inputs";
import {
  createSpinner,
  hideSpinner,
  showSpinner,
  Tooltip,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import {
  ColumnsModel,
  QueryBuilderComponent,
  RuleModel,
} from "@syncfusion/ej2-react-querybuilder";
import moment from "moment";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { msalInstance } from "../..";
//import { RAFEmptyStateProps } from '../Navigation/RAFEmptyState';
import loaderIcon from "../../assets/LoaderIconCircle.svg";
import noRecordIcon from "../../assets/NoRecordIcon.svg";
import {
  BrowserIsDevice,
  Constants,
  ContentType,
  MomentFormats,
  RAFButtonConstant,
  RAFDatePickerViewFormat,
  RAFHeaderNames,
  SFColumnType,
  SystemVariable,
} from "../../constants/Common/Constants";
import {
  GetGridModelFromGridState,
  GetGridState,
  hideAllProgress,
  hideProgress,
  SetGridState,
  showProgress,
} from "../../RAFComponents/helpers/AppHelper";

import {
  formatSFDateOption,
  getCustomFilterBySFGridFilter,
} from "../../RAFComponents/helpers/SFGridUtils";
import {
  combineFilters,
  ConvertSystemName,
  convertUTCDateStringToLocalTimezone,
  getPureSubDomainOrHint,
  getRAFDatatypeBySFColumnType,
  /*deepEqual,*/ getSFColumnTypeByRAFDatatype,
  isArray,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  isNumber,
  isString,
  truncateAfter,
} from "../../RAFComponents/helpers/utils";
import Aux from "../../RAFComponents/hoc/Auxiliary/Auxiliary";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import "../../styles/GridStyle.scss";
import "../../styles/QueryBuilderStyle.scss";
import { getAllDataByModuleName } from "../../WorkesioRepository";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../helpers/RAFDocumentEvents";
import { RAFSort } from "../helpers/types";
import { RAFDataManager, RAFUrlAdaptor } from "../Inputs/RAFUrlAdaptor";
import { RAFDataListAdditionalProps } from "../List/RAFReportDataList";
import { ListServiceRequest } from "../models/Common/ListRequest";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
/*import {
    GridColumnHeader,
    GridColumnTemplates,
    GridColumnWidth,
    RAFGridColumnProps
} from './RAFSFGridUtils';*/
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  DropDownButtonComponent,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import * as ReactDOMClient from "react-dom/client";
import nodata_es from "../../assets/ES/nodata_es.svg";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { getGridColumnTemplateItems } from "../../helpers/ACRAFGridHelperTemplates";
import { showHideGridFilter } from "../../helpers/ACutils";
import { getURLPrefixByModuleName } from "../../helpers/Common/URLPrefixByModuleName";
import { presetDateModel } from "../helpers/RAFDateRangeHelper";
import { GetClientTerm } from "../models/Common/ClientTerminologyJM";
import { RAFDataType, RAFUIType } from "../models/Common/RAFDataType";
import RAFButtonComponent from "../Navigation/RAFButtonComponent";
import { RAFEmptyStateProps } from "../Navigation/RAFEmptyState";
import { RAFDataResultContext } from "../Providers/RAFDataResultProvider";
import RAFCustomDateFilter from "../RAFViewPanels/RAFCustomDateFilter";
import { RAFCustomFilter } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  GridColumnHeader,
  GridColumnTemplates,
  GridColumnWidth,
  RAFGridColumnProps,
} from "./RAFSFGridUtils";

export declare type RafGridFilterType = "Menu" | "Excel" | "QueryBuilder";
export declare type ExportType = "Pdf" | "Csv" | "Excel";

interface IProps {
  url?: string;
  gridId?: string;
  idField?: string;
  viewId?: string;
  viewName?: string;
  moduleName?: string;
  cssClass?: string;

  pageSize?: number;
  pageNumber?: number;
  frozenColumns?: number;
  mobileColCount?: number;
  pageSizes?: number[];

  isRemote?: boolean;
  isDynamic?: boolean;
  isAdaptive?: boolean;
  allowEditing?: boolean;
  showEditColumn?: boolean;
  showActionColumn?: boolean;
  showHeader?: boolean;
  allowSelection?: boolean;
  allowFiltering?: boolean;
  isRelatedSection?: boolean;
  filterBarVisibile?: boolean;
  disableFirstColumnTemplate?: boolean;

  rowTemplate?: any;
  templateHelpers?: any;
  emptyStateProps?: RAFEmptyStateProps;

  sortBy?: RAFSort;
  fields?: QueryAttributeJM[];
  allFields?: QueryAttributeJM[]; //used in querybuider to show filter columns which are not even in select columns
  filterType?: RafGridFilterType;
  additionalFilter?: RAFCustomFilter;
  onlineOffline?: "Online" | "Offline";
  gridTemplates?: GridColumnTemplates[];
  gridColumnWidth?: GridColumnWidth[];
  gridColumnHeader?: GridColumnHeader[];
  additionalParams?: { key: string; value?: string | string[] }[];

  created?: (grid: Grid) => void;
  rowClick?: (rowData: Object) => void;
  editClick?: (rowData: Object) => void;
  customClick?: (rowData: Object) => void;
  customClick2?: (rowData: Object) => void;
  customClick3?: (rowData: Object) => void;
  customClick4?: (rowData: Object) => void;
  customActionClick?: (rowData: Object) => void;
  rowSelected?: (selectedRows: Object[]) => void;
  toggleFilterFunction?: (args?: any) => void;
  gridLines?: "None" | "Horizontal" | "Both";

  actionButtonClicked?: (id: string, selectedRow: Object) => void;

  searchedCustomFilter?: (args: RAFCustomFilter) => void;
}

function RAFGrid4({
  ...props
}: PropsWithChildren<IProps & RAFDataListAdditionalProps>) {
  //local_variable start
  const navigate = useNavigate();
  //const BASE_URL = props.url;
  const isRelatedSection = props.isRelatedSection ?? false;
  const allowFiltering = props.allowFiltering ?? true;
  // const isAdaptive = props.isAdaptive ?? false;
  const isAdaptive = isNotNullAndUndefined(props.isAdaptive)
    ? props.isAdaptive
    : BrowserIsDevice
      ? true
      : false;
  const allowEditing = props.allowEditing ?? false;
  const showEditColumn = props.showEditColumn ?? false;
  const isRemote = props.isRemote ?? true;
  const onlineOffline = props.onlineOffline ?? "Online";
  const allowSelection = props.allowSelection ?? true;
  const mobileColCount = props.mobileColCount ?? 0;
  const emptyStateProps = props.emptyStateProps ?? null;
  const gridTemplates = props.gridTemplates ?? [];
  const isDynamic = props.isDynamic ?? false;
  const disableFirstColumnTemplate = props.disableFirstColumnTemplate ?? false;
  let filterBarVisibile = true;
  const { toggleFilterFunction } = props;
  const { instance } = useMsal();
  const rafDataResultContext = useContext(RAFDataResultContext);

  let qbObj: QueryBuilderComponent; //= useRef<Grid>(null);
  let grid: GridComponent; //= useRef<Grid>(null);
  let data; // = { result: null, count: null };
  let pageSettings: PageSettingsModel = {
    currentPage: 1,
    pageSize: isNotNullAndUndefined(props.pageSize) ? props.pageSize : 10,
    pageSizes: isNotNullAndUndefined(props.pageSizes)
      ? props.pageSizes
      : [10, 25, 50, 100],
    pageCount: 8,
  };

  let sortingOptions: SortSettingsModel = { allowUnsort: false };
  let filterSettings: FilterSettingsModel = {
    showFilterBarStatus: false,
    mode: "Immediate",
    type: "FilterBar",
  };
  let ruleModel: RuleModel = null;

  let selectionsettingsModel: SelectionSettingsModel = {
    checkboxOnly: true,
    //persistSelection: true

  };

  let groupSettings: GroupSettingsModel = {
    showDropArea: false,
    showGroupedColumn: false,
    showToggleButton: false,
    showUngroupButton: false,
  };

  if (Browser.isDevice === false) {
    filterSettings = {
      showFilterBarStatus: false,
      mode: "Immediate",
      type: "FilterBar",
    };
  } else {
    filterSettings = {
      showFilterBarStatus: false,
      mode: "Immediate",
      type: "Menu",
    };
  }

  if (
    isNotNullAndUndefined(props.filterType) &&
    props.filterType !== "QueryBuilder"
  ) {
    filterSettings.type = props.filterType;
  }

  if (isNotNullAndUndefined(props.gridId)) {
    let gridState = GetGridState(props.gridId);
    if (isNotNullAndUndefined(gridState)) {
      const gridModel = gridState.gridModel;
      if (isNotNullAndUndefined(gridModel)) {
        pageSettings = gridModel.pageSettings;
        sortingOptions = gridModel.sortSettings;
        filterSettings = gridModel.filterSettings;
        groupSettings = gridModel.groupSettings;
        //editSettings = gridModel.editSettings;
        //selectionsettingsModel = gridModel.selectionSettings;
      }
      if (isNotNullAndUndefined(gridState.ruleModel)) {
        ruleModel = gridState.ruleModel;
      }
    } else if (
      isNotNullAndUndefined(props.sortBy) &&
      isNotNullAndUndefined(props.sortBy.field)
    ) {
      sortingOptions.columns = [
        {
          field: props.sortBy.field,
          direction:
            props.sortBy.order === "descending" ? "Descending" : "Ascending",
        },
      ];
    }
  }

  const stringOperators = [
    { value: "startswith", text: "Starts With" },
    { value: "endswith", text: "Ends With" },
    { value: "contains", text: "Contains" },
    { value: "equal", text: "Equal" },
    { value: "notequal", text: "Not Equal" },
  ];

  let importRules = {
    condition: "or",
    rules: [
      //     {
      //     'label': 'Category',
      //     'field': 'Category',
      //     'type': 'string',
      //     'operator': 'equal',
      //     'value': 'Laptop'
      // }
    ],
  };
  //local_variable end

  const toggleFilterBar = useCallback(() => {
    let eFilterbar =
      grid && grid.element && grid.element.querySelector(".e-filterbar");
    //console.log({ grid }, { eFilterbar });
    if (
      isNotNullAndUndefined(eFilterbar) &&
      isNotNullAndUndefined(eFilterbar.classList)
    ) {
      if (filterBarVisibile === true) {
        eFilterbar.classList.remove("hidden");
      } else {
        eFilterbar.classList.add("hidden");
      }
      filterBarVisibile = !filterBarVisibile;
    }
  }, [grid]);

  const rendereComplete = useCallback(() => {
    // let state = {} as any; //= { skip: 0, take: 10,sorted:[] };
    // state.skip = 0;
    // state.take = 10;
    let state = { skip: 0, take: 10 } as any;
    if (isNotNullAndUndefined(props.gridId)) {
      let gridState = GetGridState(props.gridId);
      if (isNotNullAndUndefined(gridState)) {
        //let sort = { field: "", order: "" };
        //let objCustomFilter = {};
        let pageNumber = 1;
        let pageSize = 10;

        if (
          isNotNullAndUndefined(gridState.gridModel) &&
          isNotNullAndUndefined(gridState.gridModel.sortSettings) &&
          isNotNullAndUndefined(gridState.gridModel.sortSettings.columns) &&
          gridState.gridModel.sortSettings.columns.length > 0 &&
          isNotNullAndUndefined(
            gridState.gridModel.sortSettings.columns[0].field
          )
        ) {
          //sort.field = model.sortSettings.columns[0].field;
          //sort.order = model.sortSettings.columns[0].direction;
          state.sorted = [];
          if (gridState.gridModel.sortSettings.columns) {
            gridState.gridModel.sortSettings.columns.forEach((item) => {
              state.sorted.push({
                direction: item.direction,
                name: item.field,
              });
            });
          }
        } else {
          if (
            isNotNullAndUndefined(props.sortBy) &&
            isNotNullAndUndefined(props.sortBy.field)
          ) {
            //sort.field = props.sortBy.field;
            //sort.order = props.sortBy.order;

            state.sorted = [];
            state.sorted.push({
              direction: props.sortBy.order,
              name: props.sortBy.field,
            });
          } else {
            //sort = null;
          }
        }

        //parse ruleModel and send to request

        if (
          isNotNullAndUndefined(gridState.gridModel) &&
          isNotNullAndUndefined(gridState.gridModel.filterSettings) &&
          isNotNullAndUndefined(gridState.gridModel.filterSettings.columns) &&
          gridState.gridModel.filterSettings.columns.length > 0
        ) {
          // objCustomFilter = getCustomFilterBySFGridFilter1(
          //     model.filterSettings.columns
          // );
          state.where = gridState.gridModel.filterSettings.columns;
        } else if (
          isNotNullAndUndefined(gridState.ruleModel) &&
          isNotNullAndUndefined(gridState.ruleModel.rules)
        ) {
          let predicate = qbObj.getPredicate(gridState.ruleModel);
          state.where = predicate;
        }

        pageNumber =
          isNotNullAndUndefined(pageSettings) && pageSettings.currentPage > 0
            ? pageSettings.currentPage
            : 1;
        pageSize =
          isNotNullAndUndefined(pageSettings) && pageSettings.pageSize > 0
            ? pageSettings.pageSize
            : 10;

        if (pageNumber > 0) {
          state.skip = (pageNumber - 1) * pageSize;
        }
        state.take = pageSize;
      } else {
        if (
          isNotNullAndUndefined(props.sortBy) &&
          isNotNullAndUndefined(props.sortBy.field)
        ) {
          //sort.field = props.sortBy.field;
          //sort.order = props.sortBy.order;

          state.sorted = [];
          state.sorted.push({
            direction: props.sortBy.order,
            name: props.sortBy.field,
          });
        } else {
          //sort = null;
        }
      }
      //console.log({ model }, { state })
    }
    dataStateChange(state, true);
  }, [props.viewId, props.additionalFilter, onlineOffline]);

  useEffect(() => {
    //hiding filterbar for firsttime without updating value for filterBarVisibile
    let eFilterbar =
      grid && grid.element && grid.element.querySelector(".e-filterbar");
    if (
      isNotNullAndUndefined(eFilterbar) &&
      isNotNullAndUndefined(eFilterbar.classList)
    ) {
      if (filterBarVisibile === true) {
        eFilterbar.classList.remove("hidden");
      } else {
        eFilterbar.classList.add("hidden");
      }
    }

    toggleFilterFunction && toggleFilterFunction(toggleFilterBar);
  }, [toggleFilterFunction, toggleFilterBar]);

  useEffect(() => {
    //updateSampleSection();
    rendereComplete();
    setColumnsForQueryBuilder(props.allFields); //used in querybuider to show filter columns which are not even in select columns
  }, [props.viewId, rendereComplete]);

  // useEffect(() => {
  //     return () => {

  //         unsubscribeRAFEvent(RAFEventName.GridLoad, reLoadGridContent);
  //         unsubscribeRAFEvent(RAFEventName.GridExport, Export);
  //         unsubscribeRAFEvent(RAFEventName.RelatedGridLoad, rendereComplete);
  //         unsubscribeRAFEvent(RAFEventName.RelatedGridExport, Export);
  //     };
  // }, []);

  useEffect(() => {
    subscribeRAFEvent(RAFEventName.GridLoad, reLoadGridContent);
    subscribeRAFEvent(RAFEventName.GridExport, Export);
    subscribeRAFEvent(RAFEventName.ShowHideGridQBFilter, showHideGridQBFilter);
    subscribeRAFEvent(RAFEventName.RelatedGridLoad, rendereComplete);
    subscribeRAFEvent(RAFEventName.RelatedGridExport, Export);

    return () => {
      unsubscribeRAFEvent(RAFEventName.GridLoad, reLoadGridContent);
      unsubscribeRAFEvent(RAFEventName.GridExport, Export);
      unsubscribeRAFEvent(
        RAFEventName.ShowHideGridQBFilter,
        showHideGridQBFilter
      );
      unsubscribeRAFEvent(RAFEventName.RelatedGridLoad, rendereComplete);
      unsubscribeRAFEvent(RAFEventName.RelatedGridExport, Export);
    };
  });

  function reLoadGridContent(args) {
    if (isNotNullAndUndefined(args)) {
      let objGridId = args.detail.GridId;
      if (
        isNotNullAndUndefined(objGridId) &&
        grid &&
        objGridId === props.gridId
      ) {
        rendereComplete();
      }
    }
  }

  function Export(args) {
    if (isNotNullAndUndefined(args)) {
      let objGridId = args.detail.GridId;
      let exportType: ExportType = args.detail.ExportType;
      //console.log('export', exportType, grid)
      if (grid && objGridId === props.gridId) {
        if (!isRemote) {
          const exportData = data; //(DataUtil.parse.parseJson(gridData));
          if (exportType === "Pdf") {
            const exportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                ? props.moduleName
                : "Data"
                }.pdf`,
            };
            grid.pdfExport(exportProperties);
          } else if (exportType === "Csv") {
            const excelExportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                ? props.moduleName
                : "Data"
                }.csv`,
            };
            grid.csvExport(excelExportProperties);
          } else if (exportType === "Excel") {
            const excelExportProperties = {
              dataSource: exportData,
              fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                ? props.moduleName
                : "Data"
                }.xlsx`,
            };
            grid.excelExport(excelExportProperties);
          }
        } else if (onlineOffline === "Online") {
          let state = { skip: 0, take: 0 };
          execute(state).then((gridData) => {
            const exportData = gridData.result; //(DataUtil.parse.parseJson(gridData));
            if (exportType === "Pdf") {
              const exportProperties = {
                dataSource: exportData,
                fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                  ? props.moduleName
                  : "Data"
                  }.pdf`,

                //theme: {
                //    header: {
                //        font: new PdfStandardFont(PdfFontFamily.TimesRoman, 11, PdfFontStyle.Bold),
                //        //caption: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9) },
                //        //record: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 10) }
                //    }
                //},

                //theme: {
                //    header: {
                //        fontColor: '#333333',
                //        fontName: 'Segoe UI',
                //        fontSize: 12, bold: true,  //borders: { color: '#64FA50', lineStyle: 'Thin' }
                //    },
                //    record: {
                //        fontColor: '#333333',
                //        fontName: 'Segoe UI',
                //        //font: new PdfTrueTypeFont() { IsTrueType = true, FontFamily = /*you fonts famiy in form of base64string*/ },
                //        fontSize: 13
                //    },
                //    //caption: {
                //    //    fontColor: '#333333', fontName: 'Segoe UI', fontSize: 13
                //    //}
                //},
                //pageOrientation: 'Landscape',
              };
              grid.pdfExport(exportProperties);
            } else if (exportType === "Csv") {
              const excelExportProperties = {
                dataSource: exportData,
                fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                  ? props.moduleName
                  : "Data"
                  }.csv`,
              };
              grid.csvExport(excelExportProperties);
            } else if (exportType === "Excel") {
              const excelExportProperties = {
                dataSource: exportData,
                fileName: `${!IsNullOrWhiteSpace(props.moduleName)
                  ? props.moduleName
                  : "Data"
                  }.xlsx`,
              };
              grid.excelExport(excelExportProperties);
            }
          });
        }
      }
      //}, [grid])
    }
  }

  function showHideGridQBFilter(args) {
    if (isNotNullAndUndefined(args)) {
      let visible = args.detail.visible;
      let objGridId = args.detail.GridId;

      if (grid && objGridId === props.gridId) {
        showHideGridFilter(visible, grid);
      }
    }
  }

  const ajax = new Ajax({
    //type: 'GET',
    type: "POST",
    mode: true, //true means asynchronous
    contentType: ContentType.applicationJson,
    onFailure: () => {
      return false;
    },
    beforeSend: ajaxRequestBeforeSend,
    //onSuccess: (e) => { return false; }
  });

  function ajaxRequestBeforeSend(request) {
    // console.log({request})
    let _authToken = msalInstance.accessToken;
    let _currentBusinessUnitId = msalInstance.currentBusinessUnitId;
    //console.log('ajaxRequestBeforeSend _authToken', _authToken);
    //console.log('ajaxRequestBeforeSend loginUserContextValue', loginUserContextValue);
    //console.log({request})
    if (_authToken) {
      request.httpRequest.setRequestHeader(
        RAFHeaderNames.Authorization,
        `Bearer ${_authToken}`
      );
      request.httpRequest.setRequestHeader(
        RAFHeaderNames.BusinessUnitUID,
        _currentBusinessUnitId
      );
      request.httpRequest.setRequestHeader(
        RAFHeaderNames.Domain,
        getPureSubDomainOrHint()
      );
      //request.setRequestHeader("viewid", _viewId);

      // const decodedJwt = parseJwt(_authToken);
      // console.log({ decodedJwt })
      // if (decodedJwt.exp * 1000 < Date.now()) {
      //     request.httpRequest.abort();
      //     msalInstance.logInAD();
      // }
    } else {
      // request.httpRequest.abort();
      // msalInstance.logInAD();
    }
  }

  function setGridRef(g) {
    if (isNotNullAndUndefined(g)) {
      grid = g;
    }
  }

  const pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs) => {
    (args.cell as any).row.pdfGrid.repeatHeader = true;
  };

  const pdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs) => {
    if (args.column.type === SFColumnType.date) {
      if (isNotNullAndUndefined(args.value)) {
        let localDate = convertUTCDateStringToLocalTimezone(
          args.value.toString()
        );
        //args.value = (moment(localDate).format('DD/MM/YYYY'));
        args.value = moment(localDate).format(
          `${RAFDatePickerViewFormat.DATE}`
        );
      }
    }
    if (args.column.type === SFColumnType.datetime) {
      if (isNotNullAndUndefined(args.value)) {
        let localDate = convertUTCDateStringToLocalTimezone(
          args.value.toString()
        );
        args.value = moment(localDate).format(
          `${RAFDatePickerViewFormat.DATETIME}`
        );
      }
    }
  };

  const recordClicked = (e: RecordClickEventArgs) => {
    const classList = e.target.classList;
    if (
      classList.contains("e-gridchkbox") ||
      classList.contains("e-checkbox-wrapper") ||
      classList.contains("noRowclick") ||
      classList.contains("e-uncheck") ||
      classList.contains("e-check") ||
      (isNotNullAndUndefined(e.target.closest(".e-gridchkbox")) &&
        isNotNullAndUndefined(e.target.closest(".e-gridchkbox").innerHTML))
    )
      return;
    else if (
      isNotNullAndUndefined(e.target.closest(".e-unboundcell")) &&
      isNotNullAndUndefined(e.target.closest(".e-unboundcell").innerHTML)
    ) {
      return;
    } else if (
      isNotNullAndUndefined(e.target.closest(".noRowclick")) &&
      isNotNullAndUndefined(e.target.closest(".noRowclick").innerHTML)
    ) {
      return;
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-editbutton")) &&
      isNotNullAndUndefined(e.target.closest(".e-editbutton").innerHTML)
    ) {
      if (props.editClick) {
        props.editClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custombutton")) &&
      isNotNullAndUndefined(e.target.closest(".e-custombutton").innerHTML)
    ) {
      if (props.customClick) {
        props.customClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custombutton_2")) &&
      isNotNullAndUndefined(e.target.closest(".e-custombutton_2").innerHTML)
    ) {
      if (props.customClick2) {
        props.customClick2(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custombutton_3")) &&
      isNotNullAndUndefined(e.target.closest(".e-custombutton_3").innerHTML)
    ) {
      if (props.customClick3) {
        props.customClick3(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custombutton_4")) &&
      isNotNullAndUndefined(e.target.closest(".e-custombutton_4").innerHTML)
    ) {
      if (props.customClick4) {
        props.customClick4(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".e-custom-actionbutton")) &&
      isNotNullAndUndefined(
        e.target.closest(".e-custom-actionbutton").innerHTML
      )
    ) {
      if (props.customActionClick) {
        props.customActionClick(e.rowData);
      }
    } else if (
      isNotNullAndUndefined(e.target.closest(".rafGridActionContentLib")) &&
      isNotNullAndUndefined(
        e.target.closest(".rafGridActionContentLib").innerHTML
      )
    ) {
      return;
    } else {
      if (props.rowClick) {
        props.rowClick(e.rowData);
      }
    }
  };

  function getEmptyRecordMessage() {
    let emptyRecord = "";
    if (emptyStateProps) {
      emptyRecord = `<div class=${`empty-state-outer`}><div class="${`empty-state-container no-record-div${isNotNullAndUndefined(emptyStateProps.emptyStateContainerClass)
        ? " " + emptyStateProps.emptyStateContainerClass
        : ""
        }`}"><div class="row gy-3 gx-0 flex-column align-items-center justify-content-center">`;
      let stateClassName = !IsNullOrWhiteSpace(emptyStateProps.iconClass)
        ? "empty-state-icon"
        : "empty-state-image";
      if (emptyStateProps.iconClass) {
        emptyRecord =
          emptyRecord +
          "<div class='col-auto'><div class=" +
          stateClassName +
          ">";
        if (!IsNullOrWhiteSpace(emptyStateProps.iconClass)) {
          emptyRecord =
            emptyRecord +
            "<span class='" +
            emptyStateProps.iconClass +
            "'></span>";
        } else {
          let emptyStateImage = emptyStateProps.image ?? nodata_es;
          emptyRecord =
            emptyRecord +
            "<img src='" +
            emptyStateImage +
            "' alt='Icon Image' class='' />";
        }

        emptyRecord = emptyRecord + "</div></div>";
      }
      if (emptyStateProps.title) {
        emptyRecord =
          emptyRecord + "<div class='col-auto'><div class='empty-state-title'>";
        emptyRecord =
          emptyRecord +
          '<span class="no-record-text1">' +
          emptyStateProps.title +
          "</span>";
        emptyRecord = emptyRecord + "</div></div>";
      }
      if (emptyStateProps.body) {
        emptyRecord =
          emptyRecord +
          "<div class='col-auto'><div class='empty-state-content'>";
        emptyRecord = emptyRecord + "<span>" + emptyStateProps.body + "</span>";
        emptyRecord = emptyRecord + "</div></div>";
      }

      emptyRecord = emptyRecord + "</div></div></div>";
    } else {
      emptyRecord =
        '<div class="no-record-div" id="noRecordDiv11" style="text-align: center;"><img src="' +
        noRecordIcon +
        '" width="52px" class="my-3" /><span class="no-record-text">No data available at the moment!</span><span class="no-record-text1"></span></div>';
    }

    return emptyRecord;
  }

  function created() {
    let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
    if (grid && grid.element) {
      const gridParentDiv = grid.element.closest("#divGridParent");
      if (gridParentDiv) {
        const customSpinner = gridParentDiv.querySelector("#customSpinner");
        if (
          isNotNullAndUndefined(customSpinner) &&
          !IsNullOrWhiteSpace(customSpinner.innerHTML)
        ) {
          spinnerTarget = gridParentDiv.querySelector("#customSpinner");
          customSpinner.classList.remove("hidden");
        }
        ``;
      }

      // subscribeRAFEvent(RAFEventName.GridLoad, reLoadGridContent);
      // subscribeRAFEvent(RAFEventName.GridExport, Export);
      // subscribeRAFEvent(RAFEventName.RelatedGridLoad, rendereComplete);
      // subscribeRAFEvent(RAFEventName.RelatedGridExport, Export);
    }

    createSpinner({
      target: spinnerTarget,
      width: "20px",
      template:
        '<div class="row g-0 wrapper-content loadingPanelGrid"> <div class="d-flex flex-column align-items-center" > <img src=' +
        loaderIcon +
        ' width="52" /> <span class="loadingText">Loading...</span> </div> </div >',
    });
    showSpinner(spinnerTarget);

    let emptyRecord = getEmptyRecordMessage();

    if (!IsNullOrWhiteSpace(emptyRecord) && isNotNullAndUndefined(grid)) {
      if (grid["localeStrings"]) {
        grid["localeStrings"]["EmptyRecord"] = emptyRecord;
      }
      if (grid.localeObj["localeStrings"]) {
        grid.localeObj["localeStrings"]["EmptyRecord"] = emptyRecord;
      }
      grid.localeObj["currentLocale"] = {
        EmptyRecord: emptyRecord,
      };
    }

    if (isNotNullAndUndefined(grid) && isNotNullAndUndefined(props.created)) {
      props.created(grid);
    }

    /*if (showHeader) {
    
        }
        else {
            grid.getHeaderTable().classList.add('hidden');
            grid.getHeaderTable().parentElement.parentElement.classList.add('borderTop0');
            grid.getHeaderTable().parentElement.parentElement.parentElement.classList.add('borderBottom0');
        }*/
  }

  function gridLoadEvent(args) {
    //console.log('gridLoadEvent', args)
    args.requireTemplateRef = false;
  }

  function hideGridSpinner() {
    setTimeout(() => {
      let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
      if (grid && grid.element) {
        const gridParentDiv = grid.element.closest("#divGridParent");
        if (gridParentDiv) {
          const customSpinner = gridParentDiv.querySelector("#customSpinner");
          if (
            isNotNullAndUndefined(customSpinner) &&
            !IsNullOrWhiteSpace(customSpinner.innerHTML)
          ) {
            spinnerTarget = gridParentDiv.querySelector("#customSpinner");
            customSpinner.classList.add("hidden");
          }
        }
      }
      hideSpinner(spinnerTarget);
      if (grid) {
        //grid.hideSpinner();
        let mainElement = document.documentElement;
        if (grid.element) {
          mainElement = grid.element.parentElement;
        }
        const eSpinnerPane = mainElement.querySelector(".e-spinner-pane");
        if (isNotNullAndUndefined(eSpinnerPane)) {
          eSpinnerPane.classList.add("hidden");
          eSpinnerPane.classList.remove("e-spin-show");
        }
        const currentViewRecords = grid.getCurrentViewRecords();
        if (
          isNotNullAndUndefined(currentViewRecords) &&
          currentViewRecords.length > 0
        ) {
          grid.element.classList.remove("hidden");
          //grid.element.classList.add("fade-in");
        } else {
          if (
            (isNotNullAndUndefined(grid.filterSettings) &&
              isNotNullAndUndefined(grid.filterSettings.columns) &&
              grid.filterSettings.columns.length > 0) ||
            (isNotNullAndUndefined(qbObj) &&
              isNotNullAndUndefined(qbObj.rule) &&
              isNotNullAndUndefined(qbObj.rule.rules) &&
              qbObj.rule.rules.length > 0)
          ) {
            //if condition added to show grid filterbar when filter is applied and also no records found
            grid.element.classList.remove("hidden");
          } else {
            //grid.element.classList.add("hidden");
          }
        }
      }
    }, 100);
  }

  function dataBound(args) {
    if (isNotNullAndUndefined(grid)) {
      const currentViewRecords = grid.getCurrentViewRecords();
      grid.element.classList.remove("hidden");
      if (
        isNotNullAndUndefined(currentViewRecords) &&
        currentViewRecords.length > 0
      ) {
        grid.element.classList.add("fade-in");
        document.querySelector(".e-spinner-pane").classList.add("hidden");
        if (!allowFiltering) {
          grid.getHeaderContent().classList.remove("hidden");
        }
      } else {
        if (isNotNullAndUndefined(document.querySelector(".e-spinner-pane")))
          document.querySelector(".e-spinner-pane").classList.add("hidden");
        // if (!allowFiltering) {
        //   grid.getHeaderContent().classList.add("hidden");
        // }
      }

      grid.getPager().classList.add("hidden");
      if (isNotNullAndUndefined(grid.dataSource)) {
        if (
          isNotNullAndUndefined(grid.dataSource["count"]) &&
          grid.dataSource["count"] > 10
        ) {
          grid.getPager().classList.remove("hidden");
        }

        if (
          isNotNullAndUndefined(grid.dataSource["count"]) &&
          grid.dataSource["count"] > grid.pageSettings.pageSize
        ) {
          grid.getPager().classList.remove("hidden");
        } else if (
          isNotNullAndUndefined(grid.dataSource["length"]) &&
          grid.dataSource["length"] > grid.pageSettings.pageSize
        ) {
          grid.getPager().classList.remove("hidden");
        }
      }

      if (isNotNullAndUndefined(grid.filterModule)) {
        Object.assign(grid.filterModule.filterOperators, {
          startsWith: "contains",
        });
      }
      if (isNotNullAndUndefined(grid) && isNotEmptyArray(grid.getColumns())) {
        const tgridWidth = grid.widthService.getTableWidth(grid.getColumns());
        if (IsNotNullOrWhiteSpace(tgridWidth) && isNumber(Number(tgridWidth))) {
          grid.widthService.setMinwidthBycalculation(Number(tgridWidth));
        }
      }
    }

    // no record table width
    // no record table width
    let rafGridElement = isNotNullAndUndefined(grid)
      ? grid.element.closest(".rafSFGrid")
      : document.querySelector(".rafSFGrid");
    let rafGridContentContainer = isNotNullAndUndefined(grid)
      ? grid.element.closest(".grid-content-container")
      : document.querySelector(".grid-content-container");
    // let rafGridElementTableElement =
    //     rafGridElement && rafGridElement.querySelectorAll('.e-table');
    if (isNotNullAndUndefined(grid)) {
      let currentGridLength = grid.getCurrentViewRecords().length;
      // if (isNotNullAndUndefined(rafGridElementTableElement)) {
      // rafGridElementTableElement.forEach((element) => {
      //     if (currentGridLength > 0) {
      //         // element.classList.remove('no-record');
      //     } else {
      //         // element.classList.add('no-record');
      //     }
      // });
      // }
      if (isNotNullAndUndefined(rafGridContentContainer)) {
        if (currentGridLength > 0) {
          rafGridContentContainer.classList.remove("empty-grid");
        } else {
          rafGridContentContainer.classList.add("empty-grid");
        }
      }

      // let colsArray = null;
      // let childrenExist = false;

      // if (
      //     isNotNullAndUndefined(props.children) &&
      //     isNotNullAndUndefined(props.children['props']) &&
      //     isNotNullAndUndefined(props.children['props'].children)
      // ) {
      //     const children1 = React.Children.toArray(
      //         props.children['props'].children
      //     );
      //     if (
      //         isNotNullAndUndefined(children1) &&
      //         children1.length > 0 &&
      //         isNotNullAndUndefined(children1[0]['props']) &&
      //         isNotNullAndUndefined(children1[0]['props']['field'])
      //     ) {
      //         childrenExist = true;
      //         colsArray = [];

      //         children1.map((item, i) => {
      //             const child = children1[i];
      //             let rafGridColumnProps = child['props'];

      //             colsArray.push(rafGridColumnProps.field.toString());

      //         });
      //     }
      // }
      // if (childrenExist === false) {
      //     const selectedcols = props.fields;
      //     if (isNotNullAndUndefined(selectedcols)) {
      //         colsArray = [];
      //         for (var i = 0; i < selectedcols.length; i++) {
      //             colsArray.push(selectedcols[i].PropertyName);
      //         }
      //     }
      // }

      // let widthWithoutScrollbar = document.body.clientWidth;

      // if (isNotNullAndUndefined(colsArray)) {
      //     //    if (widthWithoutScrollbar < 768 && colsArray.length > 2) {
      //     //        grid.autoFitColumns(colsArray);
      //     //    }
      //     //    if (widthWithoutScrollbar <= 1024 && widthWithoutScrollbar > 768 && colsArray.length > 5) {
      //     //        grid.autoFitColumns(colsArray);
      //     //    }
      //     //    if (widthWithoutScrollbar > 1024 && colsArray.length > 10) {
      //     //        grid.autoFitColumns(colsArray);
      //     //    }
      //     //grid.autoFitColumns();
      //     grid.autoFitColumns(colsArray);
      // }
      //grid.autoFitColumns();

      const emptyRow = rafGridElement.querySelector(".e-emptyrow>td");
      if (emptyRow) {
        // changing the message as default once data is loaded
        emptyRow.innerHTML = getEmptyRecordMessage();
      }
    }
    hideGridSpinner();
  }

  function dataStateChange(state, updateResultContext: boolean = false) {
    const objGrid = grid;
    let rafGridElement = isNotNullAndUndefined(grid)
      ? grid.element.closest(".rafSFGrid")
      : document.querySelector(".rafSFGrid");
    // let progressDiv = showProgress(rafGridElement);

    // hideAllProgress();
    execute(state).then((gridData) => {
      hideAllProgress();
      if (isNotNullAndUndefined(grid)) {
        grid.dataSource = gridData;
        // console.log('1', { updateResultContext }, { gridData });
        // if (updateResultContext === true) {
        //     console.log('1', { rafDataResultContext });
        //     if (isNotNullAndUndefined(rafDataResultContext) && isNotNullAndUndefined(rafDataResultContext.updateData)) {
        //         rafDataResultContext.updateData(gridData.result, gridData.count);
        //     }
        // }
      } else if (isNotNullAndUndefined(objGrid)) {
        objGrid.dataSource = gridData;
        //console.log('2', { updateResultContext }, { gridData });
        // if (updateResultContext === true) {
        //     console.log('2', { rafDataResultContext });
        //     if (isNotNullAndUndefined(rafDataResultContext) && isNotNullAndUndefined(rafDataResultContext.updateData)) {
        //         rafDataResultContext.updateData(gridData.result, gridData.count);
        //     }
        // }
      }
    });
  }

  function execute(state) {
    return getData(state);
  }
  function getData(state) {
    if (onlineOffline === "Offline") {
      return getAllDataByModuleName(props.moduleName).then((response) => {
        //console.log('getAllDataByModuleName response', response);
        if (isNotNullAndUndefined(response) && response.length > 0) {
          return response;
        } else {
          return {
            count: undefined,
            result: undefined,
          };
        }
      });
    } else {
      const gridParentDiv = isNotNullAndUndefined(grid)
        ? (grid.element.closest("#divGridParent") as HTMLElement)
        : null;
      let progressDiv = isNotNullAndUndefined(gridParentDiv)
        ? showProgress(gridParentDiv)
        : isNotNullAndUndefined(props.gridId)
          ? showProgress(`#grid_outer_${props.gridId}`)
          : showProgress("body", true);
      ajax.url = `${props.url}`;

      const listRequest: ListServiceRequest = new ListServiceRequest();
      listRequest.ViewUID = props.viewId;
      if (isNotNullAndUndefined(props.viewName)) {
        listRequest.ViewName = props.viewName;
      }

      const actionRequestType =
        isNotNullAndUndefined(state) &&
          isNotNullAndUndefined(state.action) &&
          isNotNullAndUndefined(state.action.requestType)
          ? state.action.requestType
          : null;
      if (
        isNotNullAndUndefined(actionRequestType) &&
        actionRequestType === "filtering"
      ) {
        listRequest.Take = isNotNullAndUndefined(state.take) ? state.take : 10;
        listRequest.Skip = 0;
      } else {
        listRequest.Take = isNotNullAndUndefined(state.take) ? state.take : 10;
        listRequest.Skip = isNotNullAndUndefined(state.skip) ? state.skip : 0;
      }

      let sortQuery = [];

      if ((state.sorted || []).length) {
        state.sorted.forEach((obj) => {
          sortQuery.push(
            obj.direction === "descending" ? `${obj.name} desc` : obj.name
          );
        });
        listRequest.Sort = sortQuery;
      }

      let objCustomFilter = null;
      //console.log('getdata', state.where);
      if (state.where) {
        objCustomFilter = getCustomFilterBySFGridFilter(state && state.where);
      }

      if (
        isNotNullAndUndefined(objCustomFilter) &&
        isNotNullAndUndefined(props.additionalFilter)
      ) {
        listRequest.CustomFilter = combineFilters(
          objCustomFilter,
          props.additionalFilter
        );
      } else if (isNotNullAndUndefined(props.additionalFilter)) {
        listRequest.CustomFilter = props.additionalFilter;
      } else if (isNotNullAndUndefined(objCustomFilter)) {
        listRequest.CustomFilter = objCustomFilter;
      }

      if (
        isNotNullAndUndefined(props.additionalParams) &&
        props.additionalParams.length > 0
      ) {
        props.additionalParams.forEach((objProp) => {
          listRequest[objProp.key] = objProp.value;
        });
      }

      ajax.data = JSON.stringify(listRequest);

      //return ajax.send().then((response) => {
      return repositoryActions
        .postDataAndGetResponse(
          props.url,
          listRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);

          //let data = JSON.parse(response);
          //return { result: data['d']['results'], count: parseInt(data['d']['__count'], 10) };

          if (isNotNullAndUndefined(response)) {
            //let data = JSON.parse(response as any);
            let data = response.data;

            if (props.searchedCustomFilter) {
              props.searchedCustomFilter(objCustomFilter);
            }
            let gridData = { ...data };
            if (
              isNotNullAndUndefined(gridData) &&
              isNotNullAndUndefined(gridData.ResultTable)
            ) {
              return {
                count: gridData.TotalRows,
                result: gridData.ResultTable,
              };
            } else {
              if (
                isNotNullAndUndefined(gridData) &&
                isNotNullAndUndefined(gridData.Entities)
              ) {
                return {
                  count: gridData.TotalCount,
                  result: gridData.Entities,
                };
              } else {
                if (isArray(response.data)) {
                  return response.data;
                  // return {
                  //   count: gridData.length,
                  //   result: gridData,
                  // };
                }
                return {
                  count: undefined,
                  result: undefined,
                };
              }
            }
          } else {
            return {
              count: undefined,
              result: undefined,
            };
          }
        });
    }
  }

  function rowSelected() {
    if (isNotNullAndUndefined(grid)) {
      //selectedRecords = grid.getSelectedRecords();
      if (props.rowSelected) {
        props.rowSelected(grid.getSelectedRecords());
      }
    }
  }

  function rowDeSelected() {
    if (isNotNullAndUndefined(grid)) {
      //selectedRecords = grid.getSelectedRecords();
      if (props.rowSelected) {
        props.rowSelected(grid.getSelectedRecords());
      }
    }
  }

  function actionBegin(args) {
    const actionRequestType = args.requestType;
    if (actionRequestType === "save") {
      const objData = args.data;
      args.cancel = true;
      grid.closeEdit(); //cancel the edit
      if (props.saveData) {
        props.saveData(objData, props.idField).then((gridData) => {
          grid.setRowData(objData[props.idField], objData); // update the edited data in Grid while click on the another cell
        });
      }
    }
    if (args.requestType === "filterbeforeopen") {
      args.filterModel.customFilterOperators.stringOperator = stringOperators;
      let allFields = props.allFields;
      let selectedField =
        allFields && allFields.find((x) => x.PropertyName === args.columnName);
      if (
        selectedField &&
        (selectedField.DataType === RAFDataType.Date ||
          selectedField.DataType === RAFDataType.DateTime)
      ) {
        args.filterModel.customFilterOperators.dateOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Dropdown) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Boolean) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
      if (selectedField && selectedField.DataType === RAFDataType.Lookup) {
        args.filterModel.customFilterOperators.stringOperator = [
          { value: "equal", text: "Equal" },
          { value: "notequal", text: "Not Equal" },
        ];
      }
    } else {
      const actionRequestType = args.requestType;
      switch (actionRequestType) {
        case "filtering":
        case "grouping":
        case "paging":
        case "refresh":
        case "sorting":
        case "ungrouping":
          if (
            isNotNullAndUndefined(props.gridId) &&
            isNotNullAndUndefined(grid)
          ) {
            SetGridState(
              props.gridId,
              JSON.parse(grid.getPersistData()),
              isNotNullAndUndefined(qbObj) ? qbObj.rule : null
            );
          }
          break;
        default:
          break;
      }
    }
  }

  function ConstructFilterMessage(filterColumnsModel) {
    let retVal = "";
    if (
      isNotNullAndUndefined(filterColumnsModel) &&
      filterColumnsModel.length > 0
    ) {
      for (var i = 0; i < filterColumnsModel.length; i++) {
        const col = filterColumnsModel[i];
        let value = col.value;
        const operator = col.operator;
        const field = col.field;
        let displayName = field;

        let rafGridColumnsProps: RAFGridColumnProps<any>[] = getchildrenItems();

        let matchedAttribute = isNotNullAndUndefined(props.allFields)
          ? props.allFields.find((x) => x.PropertyName === field.toString())
          : null;
        if (isNotNullAndUndefined(props.allFields)) {
          matchedAttribute = props.allFields.find(
            (x) => x.PropertyName === field.toString()
          );
        } else if (isNotEmptyArray(rafGridColumnsProps)) {
          let rafGridColumnProps = rafGridColumnsProps.find(
            (x) => x.field === field.toString()
          );
          if (isNotNullAndUndefined(rafGridColumnProps)) {
            matchedAttribute = {
              PropertyName: rafGridColumnProps.field as string,
              DisplayName:
                rafGridColumnProps.headerText ||
                rafGridColumnProps.field.toString(),
              DataType: getRAFDatatypeBySFColumnType(rafGridColumnProps.type),
              ValueJson: rafGridColumnProps.valueJson ?? null,
              UIType:
                getRAFDatatypeBySFColumnType(rafGridColumnProps.type) ===
                  RAFDataType.Dropdown
                  ? RAFUIType.RadioButton
                  : null,
            };
          }
        }

        if (isNotNullAndUndefined(matchedAttribute)) {
          displayName = matchedAttribute.DisplayName;
        }

        if (col.type === SFColumnType.date) {
          value = moment(value).format(MomentFormats.DATE);
        }

        if (
          isNullOrUndefined(props.allFields) &&
          isNotNullAndUndefined(matchedAttribute) &&
          matchedAttribute.DataType === SFColumnType.dropdown
        ) {
          let selectedValueJson = matchedAttribute.ValueJson;
          if (isNotNullAndUndefined(selectedValueJson)) {
            let selectedItem = selectedValueJson.find(
              (x) => x.Name === value || x.DisplayName === value
            );
            value = isNotNullAndUndefined(selectedItem)
              ? selectedItem.DisplayName
              : value;
          }
        }

        if (value === true) {
          value = "Yes";
        }
        if (value === false) {
          value = "No";
        }

        if (value === "yes") {
          value = "Yes";
        }
        if (value === "no") {
          value = "No";
        }

        if (isNotNullAndUndefined(value)) {
          if (i === 0) {
            retVal = `<div class='col-auto'><div class='filterMessageBarInnerItemContainer'><div class='filterMessageBarInnerItemLabel'>${displayName}:</div><div class='filterMessageBarInnerItemValue'>${value}</div></div></div>`;
          } else {
            retVal = `${retVal}<div class='col-auto'><div class='filterMessageBarInnerItemContainer'><div class='filterMessageBarInnerItemLabel'>${displayName}:</div><div class='filterMessageBarInnerItemValue'>${value}</div></div></div>`;
          }
          // if (i === 0) {
          //   retVal = `${displayName} ${getDisplayNameBySFOperator(
          //     operator
          //   )} '${value}'`;
          // } else {
          //   retVal = `${retVal} and ${displayName} ${getDisplayNameBySFOperator(
          //     operator
          //   )} '${value}'`;
          // }
        }
      }
    }
    return retVal;
  }

  function actionComplete(args) {
    if (grid) {
      const filterColumnsModel = grid.filterSettings.columns;
      const norecordsText1Element = grid.element.querySelector(
        ".no-record-div .no-record-text1"
      );
      if (isNotNullAndUndefined(norecordsText1Element)) {
        if (
          isNotNullAndUndefined(filterColumnsModel) &&
          filterColumnsModel.length > 0
        ) {
          norecordsText1Element.textContent = "";
          norecordsText1Element.textContent =
            "We're sorry. We cannot find any matches for your search criteria.";
        }
        // else if (hasOtherViews && hasOtherViews === true) {
        //     norecordsText1Element.textContent =
        //         'This view may contain filters. Please try with a different view.';
        // }
      }
      const filterMessage = ConstructFilterMessage(filterColumnsModel);
      const gridContentDiv = document.getElementById(grid && grid.element.id);
      const filterMessageBar =
        gridContentDiv &&
        gridContentDiv.parentElement.querySelector(".filterMessageBar");

      let filterBarDiv =
        '<div id="filterMessageBar" class="filterMessageBar"><div class="filterMessageBarInner d-flex fade-in"><div class="row g-2"><div class="col-auto"><button type="button" id="btn_edit_location" class="e-control e-btn e-lib filterMessageBarButton clearFilter">Clear all<i class="e-btn-icon fal fa-xmark e-icon-right"></i></button></div>' +
        filterMessage +
        "</div></div> </div>";
      // '</div> <div class="d-flex"><span style="cursor:pointer" title="Clear search criteria" class="fas fa-xmark clearFilter"></span> </div> </div> </div>';
      if (isNotNullAndUndefined(gridContentDiv) !== null) {
        if (
          isNotNullAndUndefined(filterMessage) &&
          !IsNullOrWhiteSpace(filterMessage)
        ) {
          if (isNotNullAndUndefined(filterMessageBar))
            filterMessageBar.remove();
          gridContentDiv.insertAdjacentHTML("afterbegin", filterBarDiv);
          const clearFilter = document.querySelectorAll(".clearFilter");
          if (isNotNullAndUndefined(clearFilter))
            clearFilter.forEach((button) => {
              button.addEventListener("click", () => {
                //console.log('1')
                grid.clearSelection();
                //console.log('2')
                grid.filterSettings.columns = [];
                try {
                  grid.clearFiltering();
                } catch (e) {
                  console.log("grid 3 clearFilter error", e);
                }
                //refreshData(true);
                //console.log('3')
                let pageSize = grid.pageSettings.pageSize;
                let state = { skip: 0, take: pageSize };
                dataStateChange(state, false);

                setTimeout((e) => {
                  if (grid) {
                    grid.refreshHeader();
                  }
                }, 10);
              });
            });
          // setTimeout(() => {
          //   changeGridContentHeight();
          // }, 100);
          setTimeout(() => {
            changeGridContentHeight();
            showResizeButton();
          });
        } else {
          if (isNotNullAndUndefined(filterMessageBar))
            filterMessageBar.remove();
          // setTimeout(() => {
          //   changeGridContentHeight();
          // }, 100);
          setTimeout(() => {
            changeGridContentHeight();
            showResizeButton();
          });
        }
      }

      const actionRequestType = args.requestType;
      switch (actionRequestType) {
        case "filtering":
        case "grouping":
        case "paging":
        case "refresh":
        case "sorting":
        case "ungrouping":
          if (isNotNullAndUndefined(props.gridId)) {
            SetGridState(
              props.gridId,
              JSON.parse(grid.getPersistData()),
              isNotNullAndUndefined(qbObj) ? qbObj.rule : null
            );
          }
          break;
        case "cancel":
          setTimeout(() => {
            grid.clearRowSelection();
          }, 10);
          break;
        default:
          break;
      }
    }
  }

  function changeGridContentHeight() {
    if (isNotNullAndUndefined(grid) && isNotNullAndUndefined(grid.element)) {
      let eFilterbar;
      let eColumnheader;
      if (props.showHeader !== false) {
        eFilterbar = grid.element.querySelector(".e-filterbar");
        eColumnheader = grid.element.querySelector(".e-columnheader");
      }
      let eGridpager = grid.element.querySelector(".e-gridpager");
      let filterMessageBar = document.getElementById("filterMessageBar");
      let resToolbar = grid.element.querySelector(".e-res-toolbar");

      let eGridcontent = grid.element.querySelector(".e-gridcontent");
      let eGridpagerWidth =
        isNotNullAndUndefined(eGridpager) && eGridpager.clientHeight > 0
          ? IsNotNullOrWhiteSpace(props.cssClass) &&
            (props.cssClass.includes("CardGrid") ||
              props.cssClass.includes("raf_transparent_table"))
            ? eGridpager.clientHeight + 1
            : eGridpager.clientHeight + 2
          : 0;
      let eFilterbarWidth = isNotNullAndUndefined(eFilterbar)
        ? eFilterbar.clientHeight
        : 0;
      let eColumnheaderWidth = isNotNullAndUndefined(eColumnheader)
        ? eColumnheader.clientHeight
        : 0;
      let resToolbarHeight = isNotNullAndUndefined(resToolbar)
        ? resToolbar.clientHeight
        : 0;
      let filterMessageBarWidth = isNotNullAndUndefined(filterMessageBar)
        ? filterMessageBar.offsetHeight
        : 0;
      let contentHeight =
        eGridpagerWidth +
        eFilterbarWidth +
        eColumnheaderWidth +
        filterMessageBarWidth +
        resToolbarHeight;
      if (isNotNullAndUndefined(eGridcontent)) {
        if (!props.isRelatedSection) {
          (eGridcontent as HTMLElement).style.height =
            "calc(100% - " + contentHeight + "px)";
        } else {
          (eGridcontent as HTMLElement).style.height = "auto";
        }
      }
    }
  }

  //columnTemplate start
  function hasFilterTemplate(props1: QueryAttributeJM) {
    if (isNotNullAndUndefined(props1)) {
      if (
        props1.DataType === RAFDataType.Dropdown ||
        props1.DataType === RAFDataType.Lookup ||
        props1.DataType === RAFDataType.RelatedTo ||
        props1.DataType === RAFDataType.Date ||
        props1.DataType === RAFDataType.DateTime ||
        props1.DataType === RAFDataType.Boolean ||
        props1.DataType === RAFDataType.Text ||
        props1.DataType === RAFDataType.AutoNumber
      )
        return true;
    }
    return false;
  }

  const getchildrenItems = () => {
    let children1;
    if (
      isNotNullAndUndefined(props.children) &&
      isNotNullAndUndefined(props.children["props"]) &&
      isNotNullAndUndefined(props.children["props"].children)
    ) {
      children1 = React.Children.toArray(props.children["props"].children);
    }
    if (isNotEmptyArray(children1)) {
      let retval: RAFGridColumnProps<any>[] = children1.map(
        (x) => isNotNullAndUndefined(x.props) && x.props
      );
      return retval;
    } else {
      return null;
    }
  };

  function customHeaderTemplate(item: QueryAttributeJM, data): any {
    let headerText = "";
    console.log("item", item);
    //const fieldValue = data["headerText"];
    const fieldValue = item.DisplayName;
    const gridColumnHeaders: GridColumnHeader[] = isNotNullAndUndefined(
      props.gridColumnHeader
    )
      ? props.gridColumnHeader
      : null;
    let headerVal: GridColumnHeader;

    if (isNotNullAndUndefined(gridColumnHeaders)) {
      headerVal = gridColumnHeaders.find((x) => x.field === item.PropertyName);
      console.log("headerVal", headerVal);
      if (
        isNotNullAndUndefined(headerVal) &&
        isNotNullAndUndefined(headerVal.value)
      ) {
        headerText = headerVal.value;
        return <span className="e-headertext">{headerText}</span>;
      }
    } else {
      headerText = item.DisplayName;
      return <span className="e-headertext">{headerText}</span>;
    }
    return <span className="e-headertext">{item.DisplayName}</span>;
  }

  function customHeaderText(item: QueryAttributeJM): any {
    let headerText = item.DisplayName;
    const gridColumnHeaders: GridColumnHeader[] = isNotNullAndUndefined(
      props.gridColumnHeader
    )
      ? props.gridColumnHeader
      : null;
    let headerVal: GridColumnHeader;

    if (isNotNullAndUndefined(gridColumnHeaders)) {
      headerVal = gridColumnHeaders.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(headerVal) &&
        isNotNullAndUndefined(headerVal.value)
      ) {
        headerText = headerVal.value;
      }
    }
    return headerText;
  }

  function getColumnsDirective(filteredColumnArray: QueryAttributeJM[]) {
    let rafGridColumnsProps: RAFGridColumnProps<any>[] = getchildrenItems();
    if (isNotEmptyArray(rafGridColumnsProps)) {
      if (isNotNullAndUndefined(rafGridColumnsProps[0]["field"])) {
        let retval = rafGridColumnsProps.map((rafGridColumnProps, i) => {
          let matchedAttribute: QueryAttributeJM;
          if (isNotNullAndUndefined(rafGridColumnProps)) {
            matchedAttribute = isNotNullAndUndefined(filteredColumnArray)
              ? filteredColumnArray.find(
                (x) => x.PropertyName === rafGridColumnProps.field.toString()
              )
              : {
                PropertyName: rafGridColumnProps.field.toString(),
                DisplayName:
                  rafGridColumnProps.headerText ||
                  rafGridColumnProps.field.toString(),
                DataType: getRAFDatatypeBySFColumnType(
                  rafGridColumnProps.type
                ),
                ValueJson: rafGridColumnProps.valueJson ?? null,
                UIType:
                  getRAFDatatypeBySFColumnType(rafGridColumnProps.type) ===
                    RAFDataType.Dropdown
                    ? RAFUIType.RadioButton
                    : null,
              };
          }

          let columnuid = ConvertSystemName(
            rafGridColumnProps.field.toString()
          );
          // Check if the first character is not a letter
          if (!/^[a-zA-Z]/.test(columnuid.charAt(0))) {
            columnuid = "xyz" + columnuid.slice(1);
            // Added by JD to prevent sorting errors when the first character of filed is not a letter.
            // If the first character is not a letter, 'xyz' will be added at the beginning of the column ID.
          }

          return (
            <ColumnDirective
              // id={rafGridColumnProps.field.toString()}
              key={i}
              uid={columnuid}
              field={rafGridColumnProps.field.toString()}
              type={getSFColumnTypeByRAFDatatype(rafGridColumnProps.type)}
              format={
                rafGridColumnProps.type === SFColumnType.date
                  ? formatSFDateOption
                  : null
              }
              headerText={
                rafGridColumnProps.headerText ||
                rafGridColumnProps.field.toString()
              }
              isPrimaryKey={rafGridColumnProps.isPrimaryKey}
              minWidth={
                isNotNullAndUndefined(rafGridColumnProps.minWidth)
                  ? rafGridColumnProps.minWidth
                  : "150"
              }
              // maxWidth={
              //   isNotNullAndUndefined(rafGridColumnProps.maxWidth)
              //     ? rafGridColumnProps.maxWidth
              //     : customMaxColumnWidthTemplate(matchedAttribute)
              // }
              // // //headerTemplate={customHeaderTemplate.bind(this,matchedAttribute)}
              // // {...(isNotNullAndUndefined(props.gridColumnHeader)
              // //     ? { headerTemplate: customHeaderTemplate }
              // //     : {})}
              // width={
              //   isNotNullAndUndefined(rafGridColumnProps.width)
              //     ? rafGridColumnProps.width
              //     : isNotNullAndUndefined(props.gridColumnWidth)
              //       ? customColumnWidthTemplate(matchedAttribute)
              //       : "150"
              // }
              //autoFit={true}
              visible={rafGridColumnProps.visible}
              allowEditing={
                isNotNullAndUndefined(rafGridColumnProps.isEditable)
                  ? rafGridColumnProps.isEditable
                  : false
              }
              {...(hasFilterTemplate(matchedAttribute)
                ? { filterBarTemplate: filterTemplate(matchedAttribute) }
                : {})}
              {...(matchedAttribute && Browser.isDevice === true
                ? {
                  filter: {
                    type: "Menu",
                    ui: filterMenuTemplate(matchedAttribute),
                  },
                }
                : {})}
              template={
                isNotNullAndUndefined(rafGridColumnProps.template)
                  ? rafGridColumnProps.template
                  : defaultColumnTemplate
              }
            // clipMode={i === 0 ? 'Clip' : 'Ellipsis'}
            ></ColumnDirective>
          );
        });

        if (isNullOrUndefined(retval)) {
          retval = [];
        }

        if (isAdaptive !== true && allowSelection === true) {
          retval.splice(
            0,
            0,
            <ColumnDirective
              key={-1}
              type="checkbox"
              width="40px"
              maxWidth="40px"
              minWidth="40px"
              allowResizing={false}
              //autoFit
              textAlign="Center"
              customAttributes={{ class: "gridcheckboxclass" }}
              allowEditing={false}
              editTemplate={emptyTemplate}
            />
          );
        }

        if (props.showActionColumn === true) {
          retval.splice(
            0,
            0,
            <ColumnDirective
              key={-1}
              width="40px"
              maxWidth="40px"
              minWidth="40px"
              allowResizing={false}
              //autoFit
              textAlign="Center"
              customAttributes={{ class: "gridcheckboxclass" }}
              allowEditing={false}
              template={actionButtonTemplate}
              allowFiltering={false}
              filterTemplate={emptyTemplate}
            // editTemplate={actionButtonTemplate}
            />
          );
        }
        return retval;
      }
    }
    let retval =
      filteredColumnArray &&
      filteredColumnArray.map((item, i) => {
        let columnuid = ConvertSystemName(item.PropertyName);
        // Check if the first character is not a letter
        if (!/^[a-zA-Z]/.test(columnuid.charAt(0))) {
          columnuid = "xyz" + columnuid.slice(1);
          // Added by JD to prevent sorting errors when the first character of filed is not a letter.
          // If the first character is not a letter, 'xyz' will be added at the beginning of the column ID.
        }

        return (
          <ColumnDirective
            key={i}
            uid={columnuid}
            field={item.PropertyName}
            type={getSFColumnTypeByRAFDatatype(item.DataType)}
            //headerText={GetClientTerm(clientTerminologies, item.PropertyName, item.DisplayName)}
            headerText={customHeaderText(item)}
            //headerTemplate={customHeaderTemplate.bind(this, item)}
            isPrimaryKey={
              isNotNullAndUndefined(props.idField) &&
                props.idField === item.PropertyName
                ? true
                : false
            }
            minWidth={"150"}
            // {...(item.DataType === RAFDataType.Lookup
            //     ? { minWidth: '250' }
            //     : {})}
            {...(isNotNullAndUndefined(props.gridColumnWidth)
              ? { maxWidth: customMaxColumnWidthTemplate(item) }
              : {})}
            {...(isNotNullAndUndefined(props.gridColumnWidth)
              ? { width: customColumnWidthTemplate(item) }
              : {})}
            // maxWidth={isNotNullAndUndefined(props.gridColumnWidth) ?
            //     customMaxColumnWidthTemplate(item, i) :
            //     '500'}
            // //headerTemplate={customHeaderTemplate.bind(this, item)}
            // {...(isNotNullAndUndefined(props.gridColumnHeader)
            //     ? { headerTemplate: customHeaderTemplate }
            //     : {})}
            // width={isNotNullAndUndefined(props.gridColumnWidth) ?
            //     customColumnWidthTemplate(item, i) :
            //     '150'}
            //autoFit={true}
            visible={
              Browser.isDevice === true &&
                mobileColCount > 0 &&
                i > mobileColCount - 1
                ? false
                : //  (isNullOrUndefined(mobileColCount) || mobileColCount < 1 || isAdaptive) &&
                true
            }
            {...(hasFilterTemplate(item)
              ? { filterBarTemplate: filterTemplate(item) }
              : {})}
            //filter={{ type: 'Menu', ui: filterMenuTemplate(item) }}
            {...(Browser.isDevice === true
              ? { filter: { type: "Menu", ui: filterMenuTemplate(item) } }
              : {})}
            allowEditing={
              isNotNullAndUndefined(item.IsEditable) ? item.IsEditable : false
            }
            template={defaultColumnTemplate}
          // clipMode={i === 0 ? 'Clip' : 'Ellipsis'}
          ></ColumnDirective>
        );
      });

    if (isNullOrUndefined(retval)) {
      retval = [];
    }

    if (Browser.isDevice !== true && allowSelection === true) {
      retval.splice(
        0,
        0,
        <ColumnDirective
          key={-1}
          type="checkbox"
          width="40px"
          maxWidth="40px"
          minWidth="40px"
          allowResizing={false}
          // autoFit
          textAlign="Center"
          customAttributes={{ class: "gridcheckboxclass" }}
          allowEditing={false}
          editTemplate={emptyTemplate}
        />
      );
    }

    return retval;
  }

  function emptyTemplate(props1) {
    return <div></div>;
  }

  const onActionMenuClicked = (data, selectedMenu: MenuEventArgs) => {
    if (props.actionButtonClicked) {
      props.actionButtonClicked(selectedMenu.item.id, data);
    }
  };

  function actionButtonTemplate(item) {
    return (
      <DropDownButtonComponent
        items={[
          { id: "Edit", text: "Edit" },
          { id: "Delete", text: "Delete" },
        ]}
        cssClass="bg-transparent border-0 e-caret-hide noRowclick"
        select={onActionMenuClicked.bind(this, item)}
        iconCss={RAFButtonConstant.More.IconCss}
      ></DropDownButtonComponent>
    );
  }

  const customMaxColumnWidthTemplate = (item: QueryAttributeJM) => {
    let widthVal = "";
    const gridColumnWidths: GridColumnWidth[] = props.gridColumnWidth;
    let widthValue: GridColumnWidth;
    if (
      isNotNullAndUndefined(gridColumnWidths) &&
      isNotNullAndUndefined(item)
    ) {
      widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(widthValue) &&
        isNotNullAndUndefined(widthValue.width)
      ) {
        widthVal = widthValue.width;
      } else {
        // widthVal = "300";
      }
    } else {
      //widthVal = "300";
    }
    return widthVal;
  };

  const customColumnWidthTemplate = (item: QueryAttributeJM) => {
    let widthVal = "";
    const gridColumnWidths: GridColumnWidth[] = props.gridColumnWidth;
    let widthValue: GridColumnWidth;
    if (
      isNotNullAndUndefined(gridColumnWidths) &&
      isNotNullAndUndefined(item)
    ) {
      widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
      if (
        isNotNullAndUndefined(widthValue) &&
        isNotNullAndUndefined(widthValue.width)
      ) {
        widthVal = widthValue.width;
      } else {
        widthVal = "150";
      }
    } else {
      widthVal = "150";
    }
    return widthVal;
  };

  const getAttributeFromGridColumnProps = (columnField: string) => {
    let matchedAttribute: QueryAttributeJM;
    let rafGridColumnsProps: RAFGridColumnProps<any>[] = getchildrenItems();

    if (
      isNotEmptyArray(rafGridColumnsProps) &&
      isNotNullAndUndefined(columnField)
    ) {
      const rafGridColumnProps = rafGridColumnsProps.find(
        (x) => x.field === columnField
      );
      if (isNotNullAndUndefined(rafGridColumnProps)) {
        matchedAttribute = {
          PropertyName: rafGridColumnProps.field as string,
          FieldName: rafGridColumnProps.field as string,
          DisplayName:
            rafGridColumnProps.headerText ||
            rafGridColumnProps.field.toString(),
          DataType: getRAFDatatypeBySFColumnType(rafGridColumnProps.type),
          ValueJson: rafGridColumnProps.valueJson ?? null,
          UIType:
            getRAFDatatypeBySFColumnType(rafGridColumnProps.type) ===
              RAFDataType.Dropdown
              ? RAFUIType.RadioButton
              : null,
        };
      }
    }

    return matchedAttribute;
  };

  function defaultColumnTemplate(data) {
    let item = isNotNullAndUndefined(props.allFields)
      ? props.allFields.find((x) => x.PropertyName === data.column.field)
      : getAttributeFromGridColumnProps(data.column.field);
    let isFirstColumn = false;
    if (allowSelection) {
      if (Browser.isDevice !== true) {
        // This condition added if checkbox column is hidden for device
        isFirstColumn = data.column.index === 1 ? true : false;
      } else {
        isFirstColumn = data.column.index === 0 ? true : false;
      }
    } else {
      isFirstColumn = data.column.index === 0 ? true : false;
    }
    const fieldValue = data[data.column.field];
    //const gridTemplates: GridColumnTemplates[] = props.gridTemplates;

    let columnTemplateName: string = null;
    if (isNotNullAndUndefined(item)) {
      if (
        item.DataType === RAFDataType.Text &&
        item.PropertyName === "TagsListJson"
      ) {
        columnTemplateName = "tagsTemplate";
      }
      if (
        item.DataType === RAFDataType.Text &&
        item.UIType === RAFUIType.Multiline
      ) {
        columnTemplateName = "descriptionTemplate";
      }

      if (item.DataType === RAFDataType.Date) {
        if (
          item.FieldName === "ModifiedDate" ||
          item.FieldName === "CreatedDate" ||
          item.FieldName === "LastActivityDate"
        ) {
          columnTemplateName = "dateTimeTemplate";
        } else {
          columnTemplateName = "dateTemplate";
        }
      }

      if (item.DataType === RAFDataType.DateTime) {
        columnTemplateName = "dateTimeTemplate";
      }

      if (item.DataType === RAFDataType.Dropdown) {
        columnTemplateName = "defaultDropdownTemplate";
      }

      if (item.DataType === RAFDataType.Boolean) {
        columnTemplateName = "isBooleanTemplate";
      }

      if (item.DataType === RAFDataType.Lookup && item.IsMultiSelect === true) {
        columnTemplateName = "lookupMultiSelectTemplate";
      }
      if (
        item.DataType === RAFDataType.Lookup &&
        (item.RelatedEntities === RAFEntityName.Account ||
          item.RelatedEntities === RAFEntityName.Contact)
      ) {
        columnTemplateName = "lookupTemplate";
      }
      if (item.DataType === RAFDataType.Json && item.UIType === "riskmatrix") {
        columnTemplateName = "riskmatrixJsonTemplate";
      }
      if (
        item.DataType === RAFDataType.Lookup &&
        item.UIType === "bodypartpicker"
      ) {
        columnTemplateName = "bodypartpickerTemplate";
      }

      if (isFirstColumn === true && disableFirstColumnTemplate === false) {
        columnTemplateName = "firstColumnTemplate";
      }
    }

    if (isNotNullAndUndefined(gridTemplates)) {
      let templateName = gridTemplates.find(
        (x) => x.key.toLowerCase() === data.column.field.toLowerCase()
      );
      if (
        isNotNullAndUndefined(templateName) &&
        isNotNullAndUndefined(templateName.value)
      ) {
        const getGridColumnTemplateItem =
          getGridColumnTemplateItems[templateName.value];
        if (isNotNullAndUndefined(getGridColumnTemplateItem)) {
          return getGridColumnTemplateItem.element.call(
            this,
            props.moduleName,
            allowEditing,
            isFirstColumn,
            item,
            isDynamic,
            data,
            navigate,
            onActionMenuClicked
          );
        }
      }
    }

    if (isNotNullAndUndefined(columnTemplateName)) {
      const getGridColumnTemplateItem =
        getGridColumnTemplateItems[columnTemplateName];
      if (isNotNullAndUndefined(getGridColumnTemplateItem)) {
        return getGridColumnTemplateItem.element.call(
          this,
          props.moduleName,
          allowEditing,
          isFirstColumn,
          item,
          isDynamic,
          data,
          navigate,
          onActionMenuClicked
        );
      }
    }

    if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Form) {
      return (
        <Aux>
          {!IsNullOrWhiteSpace(fieldValue) ? JSON.stringify(fieldValue) : ""}
        </Aux>
      );
    } else {
      return <Aux>{!IsNullOrWhiteSpace(fieldValue) ? fieldValue : ""}</Aux>;
    }
  }

  function filterTemplate(props1: QueryAttributeJM) {
    const objSuffix = `${Constants.idSuffix}${IsNullOrWhiteSpace(props.viewId)
      ? IsNullOrWhiteSpace(props.viewName)
        ? IsNullOrWhiteSpace(props.gridId)
          ? ""
          : props.gridId
        : props.viewName
      : props.viewId
      }`;
    let filterVal = null;
    let filterColArray: PredicateModel[] = null;
    let filterValArray = null;
    if (isNotNullAndUndefined(props.gridId)) {
      let model = GetGridModelFromGridState(props.gridId);
      if (isNotNullAndUndefined(model)) {
        const objfilterSettings = model.filterSettings;
        if (
          isNotNullAndUndefined(objfilterSettings) &&
          isNotNullAndUndefined(objfilterSettings.columns)
        ) {
          const objFilterCol = objfilterSettings.columns.find(
            (x) => x.field === props1.PropertyName
          );
          const objFilterColArray = objfilterSettings.columns.filter(
            (x) => x.field === props1.PropertyName
          );
          if (isNotNullAndUndefined(objFilterColArray)) {
            filterColArray = objFilterColArray;
            filterValArray = objFilterColArray.map((_) => _.value);
          }
          if (isNotNullAndUndefined(objFilterCol)) {
            filterVal = objFilterCol.value;
          }
        }
      }
    }
    let retVal;
    if (props1) {
      if (props1.DataType === RAFDataType.Dropdown) {
        // to remove the filter from status column for incident module done by hari
        if (
          (props.moduleName === "incident" ||
            props.moduleName === "risk" ||
            props.moduleName === "complaint_register") &&
          props1.PropertyName === "status"
        ) {
          retVal = {
            create: () => {
              let valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              valueElement.id = props1.PropertyName + objSuffix;
              return valueElement;
            },
            write: (args) => {
              let textBox = new TextBox({
                type: "text",
                value: args.values
                  ? args.values
                  : isNotNullAndUndefined(filterVal)
                    ? filterVal
                    : null,
                change: (e) => {
                  if (e.value) {
                  } else {
                    grid.removeFilteredColsByField(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      true
                    );
                  }
                },
                placeholder: props1.DisplayName,
                showClearButton: true,
              });
              textBox.appendTo("#" + args.element.id);
            },
          };
        } else {
          if (
            isNotNullAndUndefined(props1.UIType) &&
            props1.UIType === RAFUIType.ComboBox
          ) {
            retVal = {
              create: () => {
                let valueElement = document.createElement("input");
                valueElement.setAttribute("type", "text");
                valueElement.id = props1.PropertyName + objSuffix;
                return valueElement;
              },
              write: (args) => {
                let dataSource = [];
                if (props1.IsRequired === false) {
                  dataSource.push({
                    label: "None",
                    value: "None",
                  });
                }
                props1.ValueJson &&
                  props1.ValueJson.forEach((opt) => {
                    dataSource.push({
                      label: opt.DisplayName,
                      value: opt.DisplayName,
                    });
                  });

                let fields1 = { text: "label", value: "value" };
                let comboBox = new ComboBox({
                  dataSource: dataSource,
                  fields: fields1,
                  value: args.values
                    ? args.values
                    : isNotNullAndUndefined(filterVal)
                      ? filterVal
                      : null,
                  change: (e) => {
                    if (e.itemData)
                      grid &&
                        grid.filterByColumn(
                          truncateAfter(args.element.id, Constants.idSuffix),
                          "contains",
                          e.itemData !== null ? e.itemData.value : null
                        );
                    else
                      grid &&
                        grid.removeFilteredColsByField(
                          truncateAfter(args.element.id, Constants.idSuffix),
                          true
                        );
                  },
                  placeholder: props1.DisplayName,
                  showClearButton: true,
                });
                comboBox.appendTo("#" + args.element.id);
              },
            };
          } else {
            retVal = {
              create: () => {
                let valueElement = document.createElement("input");
                valueElement.setAttribute("type", "text");
                valueElement.id = props1.PropertyName + objSuffix;
                return valueElement;
              },
              write: (args) => {
                let dataSource = [];
                if (props1.IsRequired === false) {
                  dataSource.push({
                    label: "None",
                    value: "None",
                  });
                }
                props1.ValueJson &&
                  props1.ValueJson.forEach((opt) => {
                    dataSource.push({
                      label: opt.DisplayName,
                      value:
                        isNotNullAndUndefined(props1.UIType) &&
                          (props1.UIType === RAFUIType.RadioButton ||
                            props1.UIType === RAFUIType.ToggleButton)
                          ? opt.Name
                          : opt.DisplayName,
                    });
                  });
                let fields1 = { text: "label", value: "value" };
                let dropDownObj = new DropDownList({
                  dataSource: dataSource,
                  fields: fields1,
                  value: args.values
                    ? args.values
                    : isNotNullAndUndefined(filterVal)
                      ? filterVal
                      : null,
                  change: (e) => {
                    if (e.itemData)
                      grid &&
                        grid.filterByColumn(
                          truncateAfter(args.element.id, Constants.idSuffix),
                          props1.IsMultiSelect ? "contains" : "equal",
                          e.itemData !== null ? e.itemData.value : null
                        );
                    else
                      grid.removeFilteredColsByField(
                        truncateAfter(args.element.id, Constants.idSuffix),
                        true
                      );
                  },
                  placeholder: props1.DisplayName,
                  showClearButton: true,
                });
                dropDownObj.appendTo("#" + args.element.id);
              },
            };
          }
        }
      } else if (props1.DataType === "RAFDataType.Lookup") {
        let url;
        if (isNotNullAndUndefined(props1.RelatedEntities)) {
          // const firstItem = props1.RelatedEntities;
          url = `${getURLPrefixByModuleName(
            props1.RelatedEntities
          )}/LookUpDropDown`;

          // url = isNotNullAndUndefined(firstItem)
          //     ? firstItem.replace(/_/g, '').replace(/\s/g, '') + '/Lookup'
          //     : null;
        }
        let retVal1 = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args) => {
            const lookupFields = { text: "Value", value: "Value" };
            let skip = 0;
            let take = Constants.DropdownFetchCount;
            let filteredSkip = 0;
            let filteredTake = Constants.DropdownFetchCount;
            const lookupData = new RAFDataManager({
              adaptor: new RAFUrlAdaptor({
                Skip: 0,
                Take: Constants.DropdownFetchCount,
                entityName: props1.RelatedEntities,
              }),
              crossDomain: true,
              url: Constants.baseAPIUrl + url,
              requestType: "POST",
              headers: [],
            });
            const setFilteringDebounce = debounce((args1) => {
              filteredSkip = 0;
              filteredTake = Constants.DropdownFetchCount;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props1.RelatedEntities,
              });
              let query = new Query();
              query =
                args1.text !== ""
                  ? query.where(
                    props1.PropertyName,
                    "contains",
                    args1.text,
                    true
                  )
                  : query;
              args1.updateData(lookupData, query);
            }, 500);
            const actionBegin = (args1) => {
              if (args1.query === undefined) {
                //temp fix to prevent repeated api calls
                args1.cancel = true;
              }
            };
            const actionComplete = (e) => {
              e.result = R.uniq(e.result);
            };
            const opened = (args) => {
              let listElement: HTMLElement = (lookupObj as any).list;
              listElement.addEventListener("scroll", (args) => {
                if (
                  listElement.scrollTop + listElement.offsetHeight + 1 >=
                  listElement.scrollHeight
                ) {
                  setScrollDebounce(args);
                }
              });
            };
            const onCreated = () => {
              if (lookupObj) {
                const tooltip = new Tooltip({
                  beforeRender: (args1) => {
                    if (isNotNullAndUndefined(tooltip)) {
                      tooltip.content = args1.target.textContent;
                    }
                  },
                  content: "Loading...",
                  position: "TopCenter",
                  target:
                    isNotNullAndUndefined(lookupObj) &&
                      isNotNullAndUndefined(lookupObj.element)
                      ? `#${lookupObj.element.id}_popup .e-list-item`
                      : ".e-list-item",
                });
                tooltip.appendTo("body");
              }
            };
            const onClosed = (args1) => {
              let openTooltips = document.querySelectorAll(
                "div.e-tooltip-wrap.e-popup-open"
              );
              if (isNotNullAndUndefined(openTooltips)) {
                openTooltips.forEach((x) => {
                  x.classList.add("hidden");
                });
              }
            };
            const setScrollDebounce = debounce((args1) => {
              let filterQuery = isNotNullAndUndefined(lookupObj.query)
                ? lookupObj.query.clone()
                : new Query();
              const filteredText =
                lookupObj["searchKeyModule"]["element"]["value"];
              if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                filteredSkip += filteredTake;
                filterQuery = filterQuery.where(
                  props1.PropertyName,
                  "contains",
                  filteredText,
                  true
                );
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                  entityName: props1.RelatedEntities,
                });
              } else {
                skip += take;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: skip,
                  Take: take,
                  entityName: props1.RelatedEntities,
                });
              }
              lookupData.executeQuery(filterQuery).then((event) => {
                lookupObj.addItem(
                  (event as any).result as { [key: string]: Object }[]
                );
              });
            }, 500);
            const lookupObj = new DropDownList({
              value: isNotNullAndUndefined(args.values)
                ? args.values
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              placeholder: props1.DisplayName,
              showClearButton: true,
              change: (e) => {
                if (e.itemData)
                  grid &&
                    grid.filterByColumn(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      "equal",
                      e.itemData !== null ? e.itemData["Value"] : null
                    );
                else
                  grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
              },
              fields: lookupFields,
              dataSource: lookupData,
              allowFiltering: true,
              filterType: "Contains",

              filtering: (e) => {
                e.preventDefaultAction = true;
                setFilteringDebounce(e);
              },
              actionBegin: actionBegin,
              actionComplete: actionComplete,
              created: onCreated,
              close: onClosed,
              open: (e) => {
                opened(e);
              },
            });
            const objElement = document.querySelector("#" + args.element.id);
            if (
              isNotNullAndUndefined(objElement) &&
              isNotNullAndUndefined(objElement.classList) &&
              !objElement.classList.contains("e-dropdownlist")
            ) {
              lookupObj.appendTo("#" + args.element.id);
            }
          },
        };
      } else if (
        props1.DataType === RAFDataType.Date ||
        props1.DataType === RAFDataType.DateTime
      ) {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args) => {
            let displayValues = isNotNullAndUndefined(args.values)
              ? args.values
              : null;
            if (isNotEmptyArray(filterValArray)) {
              if (filterValArray.length === 2) {
                if (
                  moment(filterValArray[0]).isSame(
                    moment(filterValArray[1]).add(-1, "days")
                  )
                ) {
                  // displayValues = [];
                  // displayValues.push(moment(filterValArray[0])
                  //     //.format('MM/DD/YYYY')
                  // );
                  // displayValues.push(moment(filterValArray[1]).add(-1, 'days')
                  //     //.format('MM/DD/YYYY')
                  // );

                  displayValues = [];
                  displayValues.push(moment(filterValArray[0]).toDate());
                  displayValues.push(
                    moment(filterValArray[1]).add(-1, "days").toDate()
                  );
                  //console.log('1', { displayValues });
                } else {
                  // console.log(filterValArray[1], filterColArray[1].ejpredicate['value'], moment(filterColArray[1].ejpredicate['value']).add(1, 'milliseconds'));
                  // if (moment(filterValArray[1]).add(1, 'days').isSame(moment(filterColArray[1].ejpredicate['value']).add(1, 'milliseconds'))) {
                  //     displayValues = [];
                  //     displayValues.push(moment(filterValArray[0]).toDate());
                  //     displayValues.push(moment(filterColArray[1].ejpredicate['value']).add(1, 'milliseconds').toDate());
                  //     console.log('2.0', { displayValues });

                  // }
                  // else {
                  //     displayValues = [];
                  //     displayValues.push(moment(filterValArray[0]).toDate());
                  //     displayValues.push(moment(filterValArray[1]).toDate());
                  //     console.log('2', { displayValues });
                  // }
                  displayValues = [];
                  displayValues.push(moment(filterValArray[0]).toDate());
                  displayValues.push(moment(filterValArray[1]).toDate());
                  //console.log('2', { displayValues });
                }
                // (x => {
                //     console.log('args', x, getDateComponentValue(x));
                //     x = getDateComponentValue(x);
                //     return x;
                // });
              } else {
                displayValues = [];
                filterValArray = filterValArray.forEach((x) => {
                  displayValues.push(
                    moment(x)
                      //.format('MM/DD/YYYY')
                      .toDate()
                  );
                  //console.log('2', { displayValues });
                });
              }
            } else if (isNotNullAndUndefined(filterVal)) {
              displayValues = [];
              displayValues.push(
                moment(filterVal)
                  //.format('MM/DD/YYYY')
                  .toDate()
              );
              //console.log('3', { displayValues });
            }
            const datePickerObj = new DateRangePicker({
              //value: displayValues,
              value: isNotEmptyArray(displayValues) ? displayValues : null,
              presets: presetDateModel,
              format: RAFDatePickerViewFormat.DATE,
              //format: RAFDatePickerViewFormat.DATETIME,
              change: (e) => {
                if (e.value) {
                  grid.filterSettings.columns =
                    grid.filterSettings.columns.filter(
                      (x) =>
                        x.field !==
                        truncateAfter(args.element.id, Constants.idSuffix)
                    );
                  let eValue1 = e.value !== null ? e.value[0] : null;
                  let eValue2 = e.value !== null ? e.value[1] : null;
                  if (
                    isNotNullAndUndefined(eValue1) &&
                    isNotNullAndUndefined(eValue2) &&
                    moment(eValue1).isSame(eValue2)
                  ) {
                    grid.filterSettings.columns.push({
                      value: eValue1,
                      operator: "greaterthanorequal",
                      field: truncateAfter(args.element.id, Constants.idSuffix),
                      predicate: "and",
                    });
                    grid.filterSettings.columns.push({
                      value: moment(eValue2).add(1, "days").toDate(),
                      //operator: 'lessthanorequal',
                      operator: "lessthan",
                      field: truncateAfter(args.element.id, Constants.idSuffix),
                      predicate: "and",
                    });
                  } else {
                    grid.filterSettings.columns.push({
                      value: eValue1,
                      operator: "greaterthanorequal",
                      field: truncateAfter(args.element.id, Constants.idSuffix),
                      predicate: "and",
                    });
                    grid.filterSettings.columns.push({
                      value: eValue2,
                      operator: "lessthanorequal",
                      //operator: 'lessthan',
                      field: truncateAfter(args.element.id, Constants.idSuffix),
                      predicate: "and",
                    });
                  }
                } else {
                  grid.filterSettings.columns =
                    grid.filterSettings.columns.filter(
                      (x) =>
                        x.field !==
                        truncateAfter(args.element.id, Constants.idSuffix)
                    );
                  grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
                }
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
              allowEdit: false,
            });
            const objElement = document.querySelector("#" + args.element.id);
            if (
              isNotNullAndUndefined(objElement) &&
              isNotNullAndUndefined(objElement.classList) &&
              !objElement.classList.contains("e-daterangepicker")
            ) {
              datePickerObj.appendTo("#" + args.element.id);
            }
          },
        };
      } else if (props1.DataType === RAFDataType.Boolean) {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args) => {
            let dataSource = [
              { label: "Yes", value: true },
              { label: "No", value: false },
            ];
            let fields1 = { text: "label", value: "value" };
            let dropDownObj = new DropDownList({
              dataSource: dataSource,
              fields: fields1,
              value: args.values
                ? args.values
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              change: (e) => {
                if (e.itemData)
                  grid.filterByColumn(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    "equal",
                    e.itemData !== null ? e.itemData.value : null
                  );
                else
                  grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
            });
            dropDownObj.appendTo("#" + args.element.id);
          },
        };
      } else {
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args) => {
            let textBox = new TextBox({
              type: "text",
              value: args.values
                ? args.values
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              change: (e) => {
                if (e.value) {
                } else {
                  grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
                }
              },
              placeholder: props1.DisplayName,
              showClearButton: true,
            });
            textBox.appendTo("#" + args.element.id);
          },
        };
      }

      if (
        props.moduleName === RAFEntityName.Task &&
        props1.PropertyName === "Title"
      ) {
        const url = "TaskTitle/LookUp";
        retVal = {
          create: () => {
            let valueElement = document.createElement("input");
            valueElement.setAttribute("type", "text");
            valueElement.id = props1.PropertyName + objSuffix;
            return valueElement;
          },
          write: (args) => {
            const lookupFields = { text: "Value", value: "Value" };
            let skip = 0;
            let take = Constants.DropdownFetchCount;
            let filteredSkip = 0;
            let filteredTake = Constants.DropdownFetchCount;
            const lookupData = new RAFDataManager({
              adaptor: new RAFUrlAdaptor({
                Skip: 0,
                Take: Constants.DropdownFetchCount,
                entityName: props1.RelatedEntities,
              }),
              crossDomain: true,
              url: Constants.baseAPIUrl + url,
              requestType: "POST",
              headers: [],
            });
            const setFilteringDebounce = debounce((args1) => {
              filteredSkip = 0;
              filteredTake = Constants.DropdownFetchCount;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props1.RelatedEntities,
              });
              let query = new Query();
              query =
                args1.text !== ""
                  ? query.where(
                    props1.PropertyName,
                    "contains",
                    args1.text,
                    true
                  )
                  : query;
              args1.updateData(lookupData, query);
            }, 500);
            const actionBegin = (args1) => {
              if (args1.query === undefined) {
                //temp fix to prevent repeated api calls
                args1.cancel = true;
              }
            };
            const actionComplete = (e) => {
              e.result = R.uniq(e.result);
            };
            const opened = (args) => {
              let listElement: HTMLElement = (comboBox as any).list;
              listElement.addEventListener("scroll", (args) => {
                if (
                  listElement.scrollTop + listElement.offsetHeight + 1 >=
                  listElement.scrollHeight
                ) {
                  setScrollDebounce(args);
                }
              });
            };
            const onCreated = () => {
              if (comboBox) {
                const tooltip = new Tooltip({
                  beforeRender: (args1) => {
                    if (isNotNullAndUndefined(tooltip)) {
                      tooltip.content = args1.target.textContent;
                    }
                  },
                  content: "Loading...",
                  position: "TopCenter",
                  target:
                    isNotNullAndUndefined(comboBox) &&
                      isNotNullAndUndefined(comboBox.element)
                      ? `#${comboBox.element.id}_popup .e-list-item`
                      : ".e-list-item",
                });
                tooltip.appendTo("body");
              }
            };
            const onClosed = (args1) => {
              let openTooltips = document.querySelectorAll(
                "div.e-tooltip-wrap.e-popup-open"
              );
              if (isNotNullAndUndefined(openTooltips)) {
                openTooltips.forEach((x) => {
                  x.classList.add("hidden");
                });
              }
            };
            const setScrollDebounce = debounce((args1) => {
              let filterQuery = isNotNullAndUndefined(comboBox.query)
                ? comboBox.query.clone()
                : new Query();
              const filteredText =
                comboBox["searchKeyModule"]["element"]["value"];
              if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                filteredSkip += filteredTake;
                filterQuery = filterQuery.where(
                  props1.PropertyName,
                  "contains",
                  filteredText,
                  true
                );
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                  entityName: props1.RelatedEntities,
                });
              } else {
                skip += take;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: skip,
                  Take: take,
                  entityName: props1.RelatedEntities,
                });
              }
              lookupData.executeQuery(filterQuery).then((event) => {
                comboBox.addItem(
                  (event as any).result as { [key: string]: Object }[]
                );
              });
            }, 500);
            const comboBox = new ComboBox({
              value: isNotNullAndUndefined(args.values)
                ? args.values
                : isNotNullAndUndefined(filterVal)
                  ? filterVal
                  : null,
              placeholder: props1.DisplayName,
              showClearButton: true,
              change: (e) => {
                if (e.itemData)
                  grid &&
                    grid.filterByColumn(
                      truncateAfter(args.element.id, Constants.idSuffix),
                      "contains",
                      e.itemData !== null ? e.itemData["Value"] : null
                    );
                else
                  grid.removeFilteredColsByField(
                    truncateAfter(args.element.id, Constants.idSuffix),
                    true
                  );
              },
              fields: lookupFields,
              dataSource: lookupData,
              allowFiltering: true,
              filterType: "Contains",

              filtering: (e) => {
                e.preventDefaultAction = true;
                setFilteringDebounce(e);
              },
              actionBegin: actionBegin,
              actionComplete: actionComplete,
              created: onCreated,
              close: onClosed,
              open: (e) => {
                opened(e);
              },
            });
            const objElement = document.querySelector("#" + args.element.id);
            if (
              isNotNullAndUndefined(objElement) &&
              isNotNullAndUndefined(objElement.classList) &&
              !objElement.classList.contains("e-dropdownlist")
            ) {
              comboBox.appendTo("#" + args.element.id);
            }
          },
        };
      }
    }
    return retVal;
  }

  function filterMenuTemplate(props1: QueryAttributeJM): any {
    let filterVal = null;
    if (isNotNullAndUndefined(props.gridId)) {
      let model = GetGridModelFromGridState(props.gridId);
      if (isNotNullAndUndefined(model)) {
        const objfilterSettings = model.filterSettings;
        if (
          isNotNullAndUndefined(objfilterSettings) &&
          isNotNullAndUndefined(objfilterSettings.columns)
        ) {
          const objFilterCol = objfilterSettings.columns.filter(
            (x) => x.field === props1.PropertyName
          );
          if (isNotNullAndUndefined(objFilterCol)) {
            if (objFilterCol.length > 1) {
              filterVal = [];
              objFilterCol.forEach((itemVal) => {
                filterVal.push(itemVal.value);
              });
            } else {
              filterVal = objFilterCol[0] && objFilterCol[0].value;
            }
          }
        }
      }
    }

    let retVal;

    let textboxObj;
    let lookupObject;
    let dropDownObj;
    let booleandropDownObj;
    let daterangepickerObj;
    let datepickerObj1;

    if (props1) {
      if (
        props1.DataType === RAFDataType.Text ||
        props1.DataType === RAFDataType.Lookup ||
        props1.DataType === RAFDataType.RelatedTo
      ) {
        retVal = {
          create: (args: { target: Element; column: object }) => {
            const flValInput: HTMLElement = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);
            textboxObj = new TextBox({
              placeholder: props1.DisplayName,
              type: "text",
              showClearButton: true,
            });
            textboxObj.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              textboxObj.value
            );
          },
          write: (args) => {
            textboxObj.value = args.filteredValue
              ? args.filteredValue.toString()
              : null;
          },
        };
      }
      if (props1.DataType === RAFDataType.Dropdown) {
        if (
          isNotNullAndUndefined(props1.UIType) &&
          props1.UIType === RAFUIType.ComboBox
        ) {
          let dataSource = [];
          let fields1 = { text: "label", value: "value" };
          if (props1.IsRequired === false) {
            dataSource.push({
              label: "None",
              value: "None",
            });
          }
          props1.ValueJson &&
            props1.ValueJson.forEach((opt) => {
              dataSource.push({
                label: opt.DisplayName,
                value: opt.DisplayName,
              });
            });
          retVal = {
            create: (args: { target: Element; column: object }) => {
              const flValInput = createElement("input", {
                id: "prop_" + props1.PropertyName,
                className: "flm-input",
              });
              args.target.appendChild(flValInput);
              dropDownObj = new ComboBox({
                dataSource: dataSource,
                fields: fields1,
                placeholder: "Select " + props1.DisplayName,
              });
              dropDownObj.appendTo(flValInput);
            },
            read: (args) => {
              args.fltrObj.filterByColumn(
                args.column.field,
                args.operator,
                dropDownObj.value as any
              );
            },
            write: (args) => {
              dropDownObj.value = args.filteredValue;
            },
          };
        } else {
          let dataSource = [];
          let fields1 = { text: "label", value: "value" };
          if (props1.IsRequired === false) {
            dataSource.push({
              label: "None",
              value: "None",
            });
          }
          props1.ValueJson &&
            props1.ValueJson.forEach((opt) => {
              dataSource.push({
                label: opt.DisplayName,
                value:
                  isNotNullAndUndefined(props1.UIType) &&
                    (props1.UIType === RAFUIType.RadioButton ||
                      props1.UIType === RAFUIType.ToggleButton ||
                      RAFUIType.SmileyToggle)
                    ? opt.Name
                    : opt.DisplayName,
              });
            });
          retVal = {
            create: (args: { target: Element; column: object }) => {
              const flValInput = createElement("input", {
                id: "prop_" + props1.PropertyName,
                className: "flm-input",
              });
              args.target.appendChild(flValInput);
              dropDownObj = new DropDownList({
                dataSource: dataSource,
                fields: fields1,
                placeholder: "Select " + props1.DisplayName,
              });
              dropDownObj.appendTo(flValInput);
            },
            read: (args) => {
              args.fltrObj.filterByColumn(
                args.column.field,
                args.operator,
                dropDownObj.value as any
              );
            },
            write: (args) => {
              dropDownObj.value = args.filteredValue;
            },
          };
        }
      }
      if (props1.DataType === RAFDataType.Boolean) {
        let dataSource = [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ];
        let fields1 = { text: "label", value: "value" };
        retVal = {
          create: (args: { target: Element; column: object }) => {
            const flValInput = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);
            booleandropDownObj = new DropDownList({
              dataSource: dataSource,
              fields: fields1,
              placeholder: "Select " + props1.DisplayName,
            });
            booleandropDownObj.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              booleandropDownObj.value as any
            );
          },
          write: (args) => {
            booleandropDownObj.value = args.filteredValue;
          },
        };
      }

      if (props1.DataType === "RAFDataType.Lookup") {
        let url;
        if (isNotNullAndUndefined(props1.RelatedEntities)) {
          const firstItem = props1.RelatedEntities;
          // url = isNotNullAndUndefined(firstItem)
          //     ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
          //     : null;
          url = `${getURLPrefixByModuleName(
            props1.RelatedEntities
          )}/LookUpDropDown`;
        }

        let retVal1 = {
          create: (args: { target: Element; column: object }) => {
            const flValInput = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);
            const lookupFields = { text: "Value", value: "Value" };
            //let isFiltering: boolean = false;
            let skip = 0;
            let take = Constants.DropdownFetchCount;
            let filteredSkip = 0;
            let filteredTake = Constants.DropdownFetchCount;
            const lookupData = new RAFDataManager({
              adaptor: new RAFUrlAdaptor({
                Skip: 0,
                Take: Constants.DropdownFetchCount,
                entityName: props1.RelatedEntities,
              }),
              crossDomain: true,
              url: Constants.baseAPIUrl + url,
              requestType: "POST",
              //enableCaching: true,
              headers: [],
            });
            const setFilteringDebounce = debounce((args1) => {
              //isFiltering = true;
              filteredSkip = 0;
              filteredTake = Constants.DropdownFetchCount;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props1.RelatedEntities,
              });
              let query = new Query();
              query =
                args1.text !== ""
                  ? query.where(
                    props1.PropertyName,
                    "contains",
                    args1.text,
                    true
                  )
                  : query;
              args1.updateData(lookupData, query);
            }, 500);
            const actionBegin = (args1) => {
              if (args1.query === undefined) {
                //temp fix to prevent repeated api calls
                args1.cancel = true;
              }
            };
            const actionComplete = (e) => {
              e.result = R.uniq(e.result);
            };
            const opened = (args1) => {
              let listElement = lookupObject.list;
              listElement.addEventListener("scroll", (args) => {
                if (
                  listElement.scrollTop + listElement.offsetHeight + 1 >=
                  listElement.scrollHeight
                ) {
                  setScrollDebounce(args);
                }
              });
            };
            const onCreated = (args1) => {
              if (lookupObject) {
                const tooltip = new Tooltip({
                  beforeRender: (args1) => {
                    if (isNotNullAndUndefined(tooltip)) {
                      tooltip.content = args1.target.textContent;
                    }
                  },
                  content: "Loading...",
                  position: "TopCenter",
                  target:
                    isNotNullAndUndefined(lookupObject) &&
                      isNotNullAndUndefined(lookupObject.element)
                      ? `#${lookupObject.element.id}_popup .e-list-item`
                      : ".e-list-item",
                });
                tooltip.appendTo("body");
              }
            };
            const onClosed = (args1) => {
              let openTooltips = document.querySelectorAll(
                "div.e-tooltip-wrap.e-popup-open"
              );
              if (isNotNullAndUndefined(openTooltips)) {
                openTooltips.forEach((x) => {
                  x.classList.add("hidden");
                });
              }
            };
            const setScrollDebounce = debounce((args1) => {
              let filterQuery = isNotNullAndUndefined(lookupObject.query)
                ? lookupObject.query.clone()
                : new Query();
              const filteredText =
                lookupObject["searchKeyModule"]["element"]["value"];
              if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                filteredSkip += filteredTake;
                filterQuery = filterQuery.where(
                  props1.PropertyName,
                  "contains",
                  filteredText,
                  true
                );
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                  entityName: props1.RelatedEntities,
                });
              } else {
                skip += take;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: skip,
                  Take: take,
                  entityName: props1.RelatedEntities,
                });
              }
              lookupData
                //.executeQuery(filterQuery.range(start, end))
                .executeQuery(filterQuery)
                .then((event) => {
                  //start = end;
                  //end += 5;
                  lookupObject.addItem(
                    (event as any).result as { [key: string]: Object }[]
                  );
                });
            }, 500);
            lookupObject = new DropDownList({
              dataSource: lookupData,
              fields: lookupFields,
              allowFiltering: true,
              filterType: "Contains",
              placeholder: "Select " + props1.DisplayName,
              filtering: (e) => {
                e.preventDefaultAction = true;
                setFilteringDebounce(e);
                //if (isNotNullAndUndefined(url)) {
                //    let query = new Query();
                //    // frame the query based on search string with filter type.
                //    query = (e.text !== "") ? query.where(props1.PropertyName, "contains", e.text, true) : query;
                //    // pass the filter data source, filter query to updateData method.
                //    //args.updateData(searchData, query);
                //    e.updateData(lookupData, query);
                //}
              },
              actionBegin: actionBegin,
              actionComplete: actionComplete,
              created: onCreated,
              close: onClosed,
              open: (e) => {
                opened(e);
              },
            });
            lookupObject.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              lookupObject.value as any
            );
          },
          write: (args) => {
            lookupObject.value = args.filteredValue;
          },
        };
      }
      if (
        props1.DataType === RAFDataType.Date ||
        props1.DataType === RAFDataType.DateTime
      ) {
        retVal = {
          create: (args: { target: Element; column: object }) => {
            let flValInput = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);

            datepickerObj1 = new DatePicker({
              placeholder: "Select " + props1.DisplayName,
              format: RAFDatePickerViewFormat.DATE,
            });

            datepickerObj1.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              datepickerObj1.value
            );
          },
          write: (args) => {
            datepickerObj1.value = args.filteredValue;
          },
        };
      }
      if (props1.DataType === "RAFDataType.Date") {
        retVal = {
          create: (args: { target: Element; column: object }) => {
            let flValInput = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);
            const fdate = [];
            grid.filterSettings.columns.forEach((col) => {
              if (col.field === props1.PropertyName) fdate.push(col.value);
            });
            daterangepickerObj = new DateRangePicker({
              presets: presetDateModel,
              format: RAFDatePickerViewFormat.DATETIME,
            });
            if (fdate.length > 0) {
              daterangepickerObj.startDate = fdate[0];
              daterangepickerObj.endDate = fdate[1];
            }
            daterangepickerObj.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              daterangepickerObj.value
            );
          },
          write: (args) => {
            daterangepickerObj.value = args.filteredValue
              ? args.filteredValue
              : isNotNullAndUndefined(filterVal)
                ? filterVal
                : null;
          },
        };
      }

      if (
        props.moduleName === RAFEntityName.Task &&
        props1.PropertyName === "Title"
      ) {
        const url = "TaskTitle/LookUp";

        retVal = {
          create: (args: { target: Element; column: object }) => {
            const flValInput = createElement("input", {
              id: props1.PropertyName,
              className: "flm-input",
            });
            args.target.appendChild(flValInput);
            const lookupFields = { text: "Value", value: "Value" };
            //let isFiltering: boolean = false;
            let skip = 0;
            let take = Constants.DropdownFetchCount;
            let filteredSkip = 0;
            let filteredTake = Constants.DropdownFetchCount;
            const lookupData = new RAFDataManager({
              adaptor: new RAFUrlAdaptor({
                Skip: 0,
                Take: Constants.DropdownFetchCount,
                entityName: props1.RelatedEntities,
              }),
              crossDomain: true,
              url: Constants.baseAPIUrl + url,
              requestType: "POST",
              //enableCaching: true,
              headers: [],
            });
            const setFilteringDebounce = debounce((args1) => {
              //isFiltering = true;
              filteredSkip = 0;
              filteredTake = Constants.DropdownFetchCount;
              lookupData.adaptor = new RAFUrlAdaptor({
                Skip: filteredSkip,
                Take: filteredTake,
                entityName: props1.RelatedEntities,
              });
              let query = new Query();
              query =
                args1.text !== ""
                  ? query.where(
                    props1.PropertyName,
                    "contains",
                    args1.text,
                    true
                  )
                  : query;
              args1.updateData(lookupData, query);
            }, 500);
            const actionBegin = (args1) => {
              if (args1.query === undefined) {
                //temp fix to prevent repeated api calls
                args1.cancel = true;
              }
            };
            const actionComplete = (e) => {
              e.result = R.uniq(e.result);
            };
            const opened = (args1) => {
              let listElement = lookupObject.list;
              listElement.addEventListener("scroll", (args) => {
                if (
                  listElement.scrollTop + listElement.offsetHeight + 1 >=
                  listElement.scrollHeight
                ) {
                  setScrollDebounce(args);
                }
              });
            };
            const onCreated = (args1) => {
              if (lookupObject) {
                const tooltip = new Tooltip({
                  beforeRender: (args1) => {
                    if (isNotNullAndUndefined(tooltip)) {
                      tooltip.content = args1.target.textContent;
                    }
                  },
                  content: "Loading...",
                  position: "TopCenter",
                  target:
                    isNotNullAndUndefined(lookupObject) &&
                      isNotNullAndUndefined(lookupObject.element)
                      ? `#${lookupObject.element.id}_popup .e-list-item`
                      : ".e-list-item",
                });
                tooltip.appendTo("body");
              }
            };
            const onClosed = (args1) => {
              let openTooltips = document.querySelectorAll(
                "div.e-tooltip-wrap.e-popup-open"
              );
              if (isNotNullAndUndefined(openTooltips)) {
                openTooltips.forEach((x) => {
                  x.classList.add("hidden");
                });
              }
            };
            const setScrollDebounce = debounce((args1) => {
              let filterQuery = isNotNullAndUndefined(lookupObject.query)
                ? lookupObject.query.clone()
                : new Query();
              const filteredText =
                lookupObject["searchKeyModule"]["element"]["value"];
              if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                filteredSkip += filteredTake;
                filterQuery = filterQuery.where(
                  props1.PropertyName,
                  "contains",
                  filteredText,
                  true
                );
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                  entityName: props1.RelatedEntities,
                });
              } else {
                skip += take;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: skip,
                  Take: take,
                  entityName: props1.RelatedEntities,
                });
              }
              lookupData
                //.executeQuery(filterQuery.range(start, end))
                .executeQuery(filterQuery)
                .then((event) => {
                  //start = end;
                  //end += 5;
                  lookupObject.addItem(
                    (event as any).result as { [key: string]: Object }[]
                  );
                });
            }, 500);
            lookupObject = new ComboBox({
              dataSource: lookupData,
              fields: lookupFields,
              allowFiltering: true,
              filterType: "Contains",
              placeholder: "Select " + props1.DisplayName,
              filtering: (e) => {
                e.preventDefaultAction = true;
                setFilteringDebounce(e);
                //if (isNotNullAndUndefined(url)) {
                //    let query = new Query();
                //    // frame the query based on search string with filter type.
                //    query = (e.text !== "") ? query.where(props1.PropertyName, "contains", e.text, true) : query;
                //    // pass the filter data source, filter query to updateData method.
                //    //args.updateData(searchData, query);
                //    e.updateData(lookupData, query);
                //}
              },
              actionBegin: actionBegin,
              actionComplete: actionComplete,
              created: onCreated,
              close: onClosed,
              open: (e) => {
                opened(e);
              },
            });
            lookupObject.appendTo(flValInput);
          },
          read: (args) => {
            args.fltrObj.filterByColumn(
              args.column.field,
              args.operator,
              lookupObject.value as any
            );
          },
          write: (args) => {
            lookupObject.value = args.filteredValue;
          },
        };
      }
    }

    return retVal;
  }
  //columnTemplate end

  //QueryBuilder start

  function setQueryBuilderRef(g) {
    if (isNotNullAndUndefined(g)) {
      qbObj = g;
    }
  }

  const updateRule = (args) => {
    console.log("updateRule1", args);
    let predicate = qbObj.getPredicate(args.rule);
    console.log("updateRule2", predicate);
    // if (isNullOrUndefined(predicate)) {
    //     grid.query = new Query()
    //         //.select(['TaskID', 'Name', 'Category', 'SerialNo', 'InvoiceNo', 'Status'])
    //         ;
    // }
    // else {
    //     grid.query = new Query()
    //         //.select(['TaskID', 'Name', 'Category', 'SerialNo', 'InvoiceNo', 'Status'])
    //         .where(predicate);
    // }
    // grid.refresh();
  };

  function setColumnsForQueryBuilder(fields1?: QueryAttributeJM[]) {
    if (isNotNullAndUndefined(qbObj)) {
      let objRetVal = [];
      if (
        isNotNullAndUndefined(props.children) &&
        isNotNullAndUndefined(props.children["props"]) &&
        isNotNullAndUndefined(props.children["props"].children)
      ) {
        const children1 = React.Children.toArray(
          props.children["props"].children
        );
        if (
          isNotNullAndUndefined(children1) &&
          children1.length > 0 &&
          isNotNullAndUndefined(children1[0]["props"]) &&
          isNotNullAndUndefined(children1[0]["props"]["field"])
        ) {
          //let retval = children1.map((item, i) => {
          //const child = children1[i];
          for (const child of children1) {
            let rafGridColumnProps = child["props"];
            let matchedAttribute = isNotNullAndUndefined(fields1)
              ? fields1.find(
                (x) => x.PropertyName === rafGridColumnProps.field.toString()
              )
              : null;
            if (isNotNullAndUndefined(matchedAttribute)) {
              objRetVal.push(matchedAttribute);
            }
          }
          //);
        }
      }
      if (isNotNullAndUndefined(objRetVal) && objRetVal.length > 0) {
      } else if (isNotNullAndUndefined(fields1)) {
        //let retval = fields && fields.map((item, i) => {
        for (const item of fields1) {
          if (isNotNullAndUndefined(item) && item.ShowInWhere === true) {
            objRetVal.push(item);
          }
        }
        //);
      }

      qbObj.columns = convertfilterModel(objRetVal);
      qbObj.setRules(ruleModel);
    }
  }

  const convertfilterModel = (
    attributes: QueryAttributeJM[]
  ): ColumnsModel[] => {
    let retVal: ColumnsModel[] = [];
    // debugger;
    let filterVal = null;
    for (var i = 0; i < attributes.length; i++) {
      const attrib = attributes[i];
      if (attrib.ShowInWhere) {
        let obj: ColumnsModel = {};
        obj.field = attrib.PropertyName;
        obj.type = getSFColumnTypeByRAFDatatype(attrib.DataType);
        if (
          attrib.DataType === RAFDataType.Text ||
          attrib.DataType === RAFDataType.AutoNumber ||
          attrib.DataType === RAFDataType.Formula
        ) {
          obj.operators = [
            { key: "Starts With", value: "startswith" },
            { key: "Ends With", value: "endswith" },
            { key: "Contains", value: "contains" },
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
            { key: "Does Not Contain", value: "notcontains" },
          ];
        }
        if (
          attrib.DataType === RAFDataType.Date ||
          attrib.DataType === RAFDataType.DateTime
        ) {
          //obj.format = 'dd/MM/yyyy';
          obj.type = SFColumnType.datetime;
          //obj.template = {
          //    create: () => {
          //        this.valueElement = document.createElement('input');
          //        this.valueElement.setAttribute('type', 'text');
          //        //this.valueElement.id = props.PropertyName;
          //        return this.valueElement;

          //    },
          //    destroy: (args: { elementId: string }) => {
          //        this.dateRangeObj = getComponent(document.getElementById(args.elementId), 'daterangepicker') as DateRangePicker;
          //        if (this.dateRangeObj) {
          //            this.dateRangeObj.destroy();
          //        }
          //    },
          //    //write: (args: { elements: Element, values: Date[], operator: string }) => {
          //    write: (args: { elements: Element, values: string | string[], operator: string }) => {
          //        let startDate: Date = null;
          //        let endDate: Date = null;
          //        if (isNotNullAndUndefined(args.values)) {
          //            if (!isArray(args.values) || isString(args.values)) {
          //                const dateRange: string[] = args.values.toString().split(",");
          //                if (isNotNullAndUndefined(dateRange)) {
          //                    if (dateRange.length > 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
          //                    }
          //                    else if (dateRange.length === 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                    }
          //                }
          //            }
          //            else {
          //                const dateRange: string[] = args.values as string[];
          //                if (isNotNullAndUndefined(dateRange)) {
          //                    if (dateRange.length > 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                        endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
          //                    }
          //                    else if (dateRange.length === 1) {
          //                        startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
          //                    }
          //                }
          //            }
          //        }
          //        this.dateRangeObj = new DateRangePicker({
          //            //value: args.values ? args.values as Date[] : null,
          //            startDate: startDate,
          //            endDate: endDate,
          //            presets: this.presetDateModel,
          //            format: RAFDatePickerViewFormat.DATE,
          //            change: (e: any) => {
          //                //console.log(e);
          //                if (isNotNullAndUndefined(e.value)) {
          //                    //let retVal = formatValue(e.value[0]) + " - " + formatValue(e.value[1]);
          //                    //console.log(retVal);
          //                    //this.qryBldrObj.notifyChange(retVal, e.element);
          //                    let filterVal: string[] = [];
          //                    filterVal.push(moment(e.startDate).format(MomentFormats.DATE).toString());
          //                    filterVal.push(moment(e.endDate).format(MomentFormats.DATE).toString());
          //                    //console.log('filterVal', filterVal);
          //                    this.qryBldrObj.notifyChange(filterVal, e.element);
          //                }
          //            }
          //        });
          //        this.dateRangeObj.appendTo('#' + args.elements.id);
          //    }
          //}
          obj.template = {
            create: () => {
              //this.valueElement = document.createElement('input');
              //this.valueElement.setAttribute('type', 'text');
              ////this.valueElement.id = props.PropertyName;
              //return this.valueElement;

              return document.createElement("div");
            },
            destroy: () => {
              //debugger;
            },
            //write: (args: { elements: Element, values: Date[], operator: string }) => {
            write: (args: {
              elements: Element;
              values: string | string[];
              operator: string;
            }) => {
              let startDate: Date = null;
              let endDate: Date = null;
              if (isNotNullAndUndefined(args.values)) {
                if (!isArray(args.values) || isString(args.values)) {
                  const dateRange: string[] = args.values.toString().split(",");
                  if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                      endDate = moment(
                        dateRange[1],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                    } else if (dateRange.length === 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                    }
                  }
                } else {
                  const dateRange: string[] = args.values as string[];
                  if (isNotNullAndUndefined(dateRange)) {
                    if (dateRange.length > 1) {
                      startDate = moment(
                        dateRange[0],
                        MomentFormats.DATE,
                        true
                      ).toDate();
                      // endDate = moment(dateRange[1], MomentFormats.DATE, true).toDate();
                    } else if (dateRange.length === 1) {
                      // startDate = moment(dateRange[0], MomentFormats.DATE, true).toDate();
                    }
                  }
                }
              }
              //this.dateRangeObj = new DateRangePicker({
              //    //value: args.values ? args.values as Date[] : null,
              //    startDate: startDate,
              //    endDate: endDate,
              //    presets: this.presetDateModel,
              //    format: RAFDatePickerViewFormat.DATE,
              //    change: (e: any) => {
              //        //console.log(e);
              //        if (isNotNullAndUndefined(e.value)) {
              //            //let retVal = formatValue(e.value[0]) + " - " + formatValue(e.value[1]);
              //            //console.log(retVal);
              //            //this.qryBldrObj.notifyChange(retVal, e.element);
              //            let filterVal: string[] = [];
              //            filterVal.push(moment(e.startDate).format(MomentFormats.DATE).toString());
              //            filterVal.push(moment(e.endDate).format(MomentFormats.DATE).toString());
              //            //console.log('filterVal', filterVal);
              //            this.qryBldrObj.notifyChange(filterVal, e.element);
              //        }
              //    }
              //});
              //this.dateRangeObj.appendTo('#' + args.elements.id);
              const root = ReactDOMClient.createRoot(args.elements);

              root.render(
                <RAFCustomDateFilter
                  values={args.values}
                  //ref={(scope) => { this.dObj = scope; }}
                  onChange={(value: string[] | string, element: Element) => {
                    //console.log("RAFCustomDateFilter value=", value);
                    qbObj.notifyChange(value, element);
                  }}
                />
              );
            },
          };
          obj.operators = [
            //{ key: 'Between', value: 'Between' },
            //{ key: 'Not Between', value: 'NotBetween' }
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isnull" },
            { key: "Is Not Empty", value: "isnotnull" },
            { key: "Is Greater Than", value: "greaterthan" },
            { key: "Is Greater Than Or Equal", value: "greaterthanorequal" },
            { key: "Is Less Than", value: "lessthan" },
            { key: "Is Less Than Or Equal", value: "lessthanorequal" },
          ];
        }
        if (attrib.DataType === RAFDataType.Dropdown) {
          let valueElement: HTMLInputElement;
          let dropDownObj: DropDownList;
          if (isNotNullAndUndefined(attrib.ValueJson)) {
            obj.values = attrib.ValueJson.map((opt: ValueJson) => {
              return opt.DisplayName;
            });
          }
          obj.template = {
            create: () => {
              valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              return valueElement;
            },
            destroy: (args: { elementId: string }) => {
              dropDownObj = getComponent(
                document.getElementById(args.elementId),
                "dropdownlist"
              ) as DropDownList;
              if (dropDownObj) {
                dropDownObj.destroy();
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              let ds: string[] = attrib.ValueJson.map((opt: ValueJson) => {
                return opt.DisplayName;
              });
              dropDownObj = new DropDownList({
                dataSource: ds,
                value: args.values ? (args.values as string) : null,
                change: (e: any) => {
                  qbObj.notifyChange(e.itemData.value, e.element);
                  console.log("e.tei", e.itemData);
                },
              });
              dropDownObj.appendTo("#" + args.elements.id);
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
          ];
        }
        if (attrib.DataType === RAFDataType.Boolean) {
          let valueElement: HTMLInputElement;
          let dropDownObj: DropDownList;
          obj.template = {
            create: () => {
              valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              return valueElement;
            },
            destroy: (args: { elementId: string }) => {
              dropDownObj = getComponent(
                document.getElementById(args.elementId),
                "dropdownlist"
              ) as DropDownList;
              if (dropDownObj) {
                dropDownObj.destroy();
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              let ds: { [key: string]: Object }[] = [
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ];
              let fields1: object = { text: "label", value: "value" };
              dropDownObj = new DropDownList({
                dataSource: ds,
                fields: fields1,
                value: args.values ? (args.values as string) : false,
                change: (e: any) => {
                  qbObj.notifyChange(e.itemData.value, e.element);
                },
              });
              dropDownObj.appendTo("#" + args.elements.id);
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
          ];
        }
        //if (attrib.DataType === RAFDataType.Boolean) {
        //    obj.template = {
        //        create: () => {
        //            this.valueElement = document.createElement('input');
        //            this.valueElement.setAttribute('type', 'checkbox');
        //            return this.valueElement;
        //        },
        //        destroy: (args: { elementId: string }) => {
        //            (getComponent(document.getElementById(args.elementId), 'checkbox') as CheckBox).destroy();
        //        },
        //        write: (args: { elements: Element, values: string }) => {
        //            this.checkboxObj = new CheckBox({
        //                change: (e: any) => {
        //                    this.qryBldrObj.notifyChange(e.checked, e.event.target);
        //                },
        //                checked: args.values === 'true' ? true : false,
        //                created: (e:any) => {
        //                    setTimeout(() => {
        //                        this.qryBldrObj && this.qryBldrObj.notifyChange(args.values === 'true' ? true : false, this.checkboxObj.element);
        //                    },100)
        //                },

        //            });

        //            this.checkboxObj.appendTo('#' + args.elements.id);
        //        }
        //    }
        //    obj.operators = [
        //        { key: 'Is', value: 'equal' },
        //        { key: 'Is Not', value: 'notequal' }];
        //}

        /*
                        if(attrib.DataType === RAFDataType.Lookup) {
                            let url;
                            //if (isNotNullAndUndefined(attrib.ValueJson)) {
                            //    const firstItem = attrib.ValueJson[0];
                            //    url = isNotNullAndUndefined(firstItem.Name) ? firstItem.Name.replace(/_/g, "").replace(/\s/g, "") + "/Lookup" : null;
                            //}
        
                            if (isNotNullAndUndefined(attrib.RelatedEntities)) {
                                const firstItem = attrib.RelatedEntities;
                                url = isNotNullAndUndefined(firstItem) ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup" : null;
                            }
        
                            obj.template = {
                                create: () => {
                                    this.valueElement = document.createElement('input');
                                    this.valueElement.setAttribute('type', 'text');
                                    return this.valueElement;
                                },
                                destroy: (args: { elementId: string }) => {
                                    this.lookupObj = getComponent(document.getElementById(args.elementId), 'autocomplete') as AutoComplete;
                                    if (this.lookupObj) {
                                        this.lookupObj.destroy();
                                    }
                                },
                                write: (args: { elements: Element, values: string[] | string, operator: string }) => {
                                    this.lookupObj = new AutoComplete({
                                        value: args.values ? args.values as string : null,
                                        change: (e: ChangeEventArgs) => {
                                            this.qryBldrObj.notifyChange((e.itemData !== null ? e.itemData['Value'] : null), e.element);
                                        },
                                        dataSource: this.data,
                                        fields: this.fields,
                                        filterType: 'Contains',
                                        showPopupButton: true, highlight: true, showClearButton: true,
                                        filtering: (e: FilteringEventArgs) => {
                                            let data: { [key: string]: Object; }[] = [];
                                            if (isNotNullAndUndefined(url)) {
                                                this.getLookupData(0, 10, url, e.text).then((dropdownData) => {
                                                    if (isNotNullAndUndefined(dropdownData) && isNotNullAndUndefined(dropdownData['result'])) {
                                                        for (let i: number = 0; i < dropdownData['result'].length; i++) {
                                                            data.push(dropdownData['result'][i] as any);
                                                        }
                                                    }
                                                    e.updateData(data, null);
                                                });
        
                                            }
                                        },
                                        dataBound: () => {
                                            if (this.lookupObj && (this.lookupObj.dataSource instanceof Array
                                                && !(this.lookupObj.dataSource as object[]).length)) {
                                                if (isNotNullAndUndefined(url)) {
        
                                                    this.getLookupData(0, 10, url, null).then((dropdownData) => {
                                                        if (this.lookupObj) {
                                                            this.lookupObj.dataSource = dropdownData['result'];
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                    this.lookupObj.appendTo('#' + args.elements.id);
                                }
                            }
                            obj.operators = [
                                { key: 'Is', value: 'equal' },
                                { key: 'Is Not', value: 'notequal' },
                                { key: 'Is Empty', value: 'isempty' },
                                { key: 'Is Not Empty', value: 'isnotempty' }];
                        }
                        */

        if (attrib.DataType === RAFDataType.Lookup) {
          let url;
          let valueElement: HTMLInputElement;
          let lookupObj: DropDownList;

          if (isNotNullAndUndefined(attrib.RelatedEntities)) {
            const firstItem = attrib.RelatedEntities;
            url = isNotNullAndUndefined(firstItem)
              ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
              : null;
          }

          obj.template = {
            create: () => {
              valueElement = document.createElement("input");
              valueElement.setAttribute("type", "text");
              valueElement.id = attrib.PropertyName;
              return valueElement;
            },
            destroy: (args: { elementId: string }) => {
              if (args.elementId && document.getElementById(args.elementId)) {
                lookupObj = getComponent(
                  document.getElementById(args.elementId),
                  "dropdownlist"
                ) as DropDownList;
                if (lookupObj) {
                  lookupObj.destroy();
                }
              }
            },
            write: (args: {
              elements: Element;
              values: string[] | string;
              operator: string;
            }) => {
              const lookupFields = { text: "Value", value: "Value" };
              //let isFiltering = false;
              let skip = 0;
              let take: number = Constants.DropdownFetchCount;
              let filteredSkip = 0;
              let filteredTake: number = Constants.DropdownFetchCount;
              const lookupData: RAFDataManager = new RAFDataManager({
                adaptor: new RAFUrlAdaptor({
                  Skip: 0,
                  Take: Constants.DropdownFetchCount,
                }),
                crossDomain: true,
                url: Constants.baseAPIUrl + url,
                requestType: "POST",
                //enableCaching: true,
              });
              const setFilteringDebounce = debounce((args1) => {
                //  isFiltering = true;
                filteredSkip = 0;
                filteredTake = Constants.DropdownFetchCount;
                lookupData.adaptor = new RAFUrlAdaptor({
                  Skip: filteredSkip,
                  Take: filteredTake,
                });
                let query = new Query();
                query =
                  args1.text !== ""
                    ? query.where(
                      attrib.PropertyName,
                      "contains",
                      args1.text,
                      true
                    )
                    : query;
                args1.updateData(lookupData, query);
              }, 500);
              const actionBegin = (args1) => {
                if (args1.query === undefined) {
                  //temp fix to prevent repeated api calls
                  args1.cancel = true;
                }
              };
              const actionComplete = (e) => {
                if (
                  isNotNullAndUndefined(attrib.RelatedEntities) &&
                  attrib.RelatedEntities === RAFEntityName.User
                ) {
                  e.result.splice(0, 0, {
                    UID: SystemVariable.CURRENTUSER,
                    Value: SystemVariable.CURRENTUSER_DISPLAYNAME,
                  });
                }
                e.result = R.uniq(e.result);
              };
              const opened = () => {
                let listElement: HTMLElement = (lookupObj as any).list;
                listElement.addEventListener("scroll", (args) => {
                  if (
                    listElement.scrollTop + listElement.offsetHeight + 1 >=
                    listElement.scrollHeight
                  ) {
                    setScrollDebounce(args);
                  }
                });
              };
              const onCreated = () => {
                if (lookupObj) {
                  const tooltip = new Tooltip({
                    beforeRender: (args1: TooltipEventArgs) => {
                      if (isNotNullAndUndefined(tooltip)) {
                        tooltip.content = args1.target.textContent;
                      }
                    },
                    content: "Loading...",
                    position: "TopCenter",
                    target:
                      isNotNullAndUndefined(lookupObj) &&
                        isNotNullAndUndefined(lookupObj.element)
                        ? `#${lookupObj.element.id}_popup .e-list-item`
                        : ".e-list-item",
                  });
                  tooltip.appendTo("body");
                }
              };
              const onClosed = () => {
                let openTooltips = document.querySelectorAll(
                  "div.e-tooltip-wrap.e-popup-open"
                );
                if (isNotNullAndUndefined(openTooltips)) {
                  openTooltips.forEach((x) => {
                    x.classList.add("hidden");
                  });
                }
              };
              const setScrollDebounce = debounce(() => {
                let filterQuery = isNotNullAndUndefined(lookupObj.query)
                  ? lookupObj.query.clone()
                  : new Query();
                const filteredText =
                  lookupObj["searchKeyModule"]["element"]["value"];
                if (
                  isNotNullAndUndefined(filteredText) &&
                  filteredText !== ""
                ) {
                  filteredSkip += filteredTake;
                  filterQuery = filterQuery.where(
                    attrib.PropertyName,
                    "contains",
                    filteredText,
                    true
                  );
                  lookupData.adaptor = new RAFUrlAdaptor({
                    Skip: filteredSkip,
                    Take: filteredTake,
                  });
                } else {
                  skip += take;
                  lookupData.adaptor = new RAFUrlAdaptor({
                    Skip: skip,
                    Take: take,
                  });
                }
                lookupData
                  //.executeQuery(filterQuery.range(start, end))
                  .executeQuery(filterQuery)
                  .then((event) => {
                    //start = end;
                    //end += 5;
                    lookupObj.addItem(
                      (event as any).result as { [key: string]: Object }[]
                    );
                  });
              }, 500);
              const lookupObj: DropDownList = new DropDownList({
                value: isNotNullAndUndefined(args.values)
                  ? (args.values as string) === SystemVariable.CURRENTUSER
                    ? SystemVariable.CURRENTUSER_DISPLAYNAME
                    : (args.values as string)
                  : isNotNullAndUndefined(filterVal)
                    ? filterVal
                    : null,
                change: (e: ChangeEventArgs) => {
                  qbObj.notifyChange(
                    e.itemData !== null
                      ? e.itemData["UID"] === SystemVariable.CURRENTUSER
                        ? e.itemData["UID"]
                        : e.itemData["Value"]
                      : null,
                    e.element
                  );
                },
                dataSource: lookupData,
                fields: lookupFields,
                filterType: "Contains",
                allowFiltering: true,
                showClearButton: true,
                filtering: (e: FilteringEventArgs) => {
                  e.preventDefaultAction = true;
                  setFilteringDebounce(e);
                  //if (isNotNullAndUndefined(url)) {
                  //    let query = new Query();
                  //    // frame the query based on search string with filter type.
                  //    query = (e.text !== "") ? query.where(attrib.PropertyName, "contains", e.text, true) : query;
                  //    // pass the filter data source, filter query to updateData method.
                  //    e.updateData(lookupData, query);
                  //}
                },
                actionBegin: actionBegin,
                actionComplete: actionComplete,
                created: onCreated,
                close: onClosed,
                open: (e) => {
                  opened();
                },
              });
              //this.lookupObj.appendTo('#' + args.elements.id);
              const objElement = document.querySelector("#" + args.elements.id);
              if (
                isNotNullAndUndefined(objElement) &&
                isNotNullAndUndefined(objElement.classList) &&
                !objElement.classList.contains("e-dropdownlist")
              ) {
                lookupObj.appendTo("#" + args.elements.id);
              }
            },
          };
          obj.operators = [
            { key: "Is", value: "equal" },
            { key: "Is Not", value: "notequal" },
            { key: "Is Empty", value: "isempty" },
            { key: "Is Not Empty", value: "isnotempty" },
          ];
        }

        //obj.label = attrib.DisplayName;
        obj.label = GetClientTerm(
          //this.state.clientTerms,
          null,
          attrib.PropertyName,
          attrib.DisplayName
        );
        retVal.push(obj);
      }
    }
    return retVal;
  };

  const ApplyFilterClicked = (e) => {
    let predicate = qbObj.getPredicate(qbObj.rule);
    console.log("ApplyFilterClicked2", predicate);
    if (isNotNullAndUndefined(grid)) {
      if (isNullOrUndefined(predicate)) {
        grid.query = new Query();
        //.select(['TaskID', 'Name', 'Category', 'SerialNo', 'InvoiceNo', 'Status'])
      } else {
        grid.query = new Query()
          //.select(['TaskID', 'Name', 'Category', 'SerialNo', 'InvoiceNo', 'Status'])
          .where(predicate);
      }
      showHideGridFilter("hide", grid);
      grid.refresh();
    }
  };

  const ResetFilterClicked = (e) => {
    console.log("ResetFilterClicked", e);
    qbObj.reset();
    if (isNotNullAndUndefined(grid)) {
      grid.query = new Query();
      //.select(['TaskID', 'Name', 'Category', 'SerialNo', 'InvoiceNo', 'Status'])

      grid.refresh();
    }
  };

  function qbHeaderTemplate(props1) {
    let items;
    let fields1;
    let qryBldrObj;
    items = [
      { key: "AND", value: "and" },
      { key: "OR", value: "or" },
    ];
    fields1 = { text: "key", value: "value" };
    let state = Object.assign({}, props1);
    qryBldrObj = getComponent(
      document.getElementById(`grid_querybuilder_${props.gridId}`),
      "query-builder"
    );
    function conditionChange(args) {
      qryBldrObj.notifyChange(args.value, args.element, "condition");
    }
    function addGroupClick(args) {
      let addbtn = args.currentTarget.offsetParent.id;
      let ddb = addbtn.split("_");
      qryBldrObj.addGroups([{ condition: "and", rules: [{}] }], ddb[1]);
    }
    function addRuleClick(args) {
      let addbtn = args.currentTarget.offsetParent.id;
      let ddb = addbtn.split("_");
      //qryBldrObj.addRules([{}], ddb[1]);
      qryBldrObj.addRules([{}], ddb[ddb.length - 1]);
      showHideGridFilter("show", grid);
      let eRuleValueDelete = document.getElementsByClassName(
        "e-rule-value-delete"
      );
      for (let i = 0; i < eRuleValueDelete.length; i++) {
        eRuleValueDelete[i].addEventListener("click", () =>
          showHideGridFilterWithTimer()
        );
      }
    }
    function showHideGridFilterWithTimer() {
      setTimeout(() => {
        showHideGridFilter("show", grid);
      }, 100);
    }

    function onClick(args) {
      qryBldrObj.deleteGroup(
        closest(args.target.offsetParent, ".e-group-container")
      );
    }
    const args = state;
    //console.log('qbHeaderTemplate', { args }, { props1 });
    return (
      <div className="query-template-control">
        <div className="e-groupheader">
          {/* <div className="e-badge e-badge-primary e-badge-lg e-badge-border d-flex align-items-center">
            <span>And</span>
          </div> */}
          {/* <DropDownListComponent id={args.ruleID + "_cndtn"} cssClass='e-custom-group-btn' width="100px" dataSource={items} fields={fields1} value={args.condition} change={conditionChange} /> */}
          <div className="e-header">
            <div className="row gx-2">
              {/* <div className="col-auto">
                <div className="e-qb-hdr-content hidden">
                  <ButtonComponent
                    className="e-grp-btn"
                    cssClass="e-primary"
                    onClick={addGroupClick}
                  >
                    Add Group
                  </ButtonComponent>
                </div>
              </div> */}
              <div className="col-12">
                <div className="e-qb-hdr-content">
                  <ButtonComponent
                    className="e-cond-btn"
                    cssClass="e-outline w-100"
                    isPrimary
                    onClick={addRuleClick}
                    iconCss={RAFButtonConstant.Add.IconCss}
                  >
                    Add Condition
                  </ButtonComponent>
                </div>
              </div>
              {(() => {
                if (args.ruleID !== "querybuilder_group0") {
                  return (
                    <div className="col-auto">
                      <div className="e-qb-hdr-content hidden">
                        <ButtonComponent
                          id={args.ruleID + "_dltbtn"}
                          cssClass="e-danger"
                          onClick={onClick}
                        >
                          Remove
                        </ButtonComponent>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleResizeButtonClick = () => {
    try {
      if (
        isNotNullAndUndefined(grid) &&
        isNotNullAndUndefined(grid.columns) &&
        grid.columns.length > 3
      ) {
        grid.autoFitColumns();
      }
    } catch (e) {
      console.log("handleResizeButtonClick", e);
    }
  };
  const showResizeButton = () => {
    let buttonDiv = document.getElementById(`btn__iconBtn_${props.gridId}`);
    if (isNotNullAndUndefined(buttonDiv)) {
      if (
        isNotNullAndUndefined(grid) &&
        isNotNullAndUndefined(grid.columns) &&
        grid.columns.length <= 3
      ) {
        buttonDiv.classList.remove("hover-child-div");
        buttonDiv.classList.add("hidden");
      }
      const filterMessageBar = grid.element.querySelector("#filterMessageBar");
      buttonDiv.style.top = `${isNotNullAndUndefined(filterMessageBar)
        ? filterMessageBar.clientHeight
        : 0
        }px`;
      buttonDiv.style.right = `0rem`;
    }
  };

  //QueryBuilder end

  return (
    <div className="position-relative hover-parent-div h-100">
      <div className={`position-relative${BrowserIsDevice ? " hidden" : ""}`}>
        <RAFButtonComponent
          idString={props.gridId}
          btnContent={"Resize"}
          className="position-absolute grid-header-button e-round hover-child-div"
          iconCss="far fa-compress"
          iconBtn
          isPrimary
          onClick={handleResizeButtonClick}
        ></RAFButtonComponent>
      </div>
      <div
        className="h-100 overflow-hidden position-relative"
        //key={Math.random()}
        id={`grid_outer_${props.gridId}`}
      >
        <div className="h-100 qb-section-outter" id="qb-section-outter">
          {allowFiltering === true && props.filterType === "QueryBuilder" ? (
            <div className="control-section qb-section h-100" id="qb-section">
              <div className="section__container">
                <div className="border-bottom py-2 px-3 d-flex align-items-center justify-content-between">
                  <div className="header-text-lg">
                    <span>Filter</span>
                  </div>
                  <ButtonComponent
                    type="button"
                    iconCss={RAFButtonConstant.DialogClose.IconCss}
                    className="primary-custom-button"
                    onClick={() => showHideGridFilter("hide", grid)}
                  ></ButtonComponent>
                </div>
                <div className="section__div customScrollBar">
                  <QueryBuilderComponent
                    width="100%"
                    dataSource={data}
                    id={`grid_querybuilder_${props.gridId}`}
                    className="border-0 customQBWidth"
                    //columns={columnData}
                    rule={importRules}
                    //ruleChange={updateRule}
                    ref={setQueryBuilderRef}
                    showButtons={{ groupInsert: false }}
                    headerTemplate={qbHeaderTemplate}
                  ></QueryBuilderComponent>
                </div>
                <div className="border-top px-3 py-1">
                  <div className="row gx-2 justify-content-end">
                    <div className="col-auto">
                      <ButtonComponent
                        type="button"
                        cssClass="e-outline"
                        onClick={ResetFilterClicked}
                        id={`btn_ResetFilter_${props.gridId}`}
                      >
                        Reset
                      </ButtonComponent>
                    </div>
                    <div className="col-auto">
                      <ButtonComponent
                        type="button"
                        onClick={ApplyFilterClicked}
                        id={`btn_ApplyFilter_${props.gridId}`}
                        isPrimary
                      // className='primary-custom-button outline'
                      >
                        Apply
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="section__div hover-table-div divGridParent"
            id="divGridParent"
          >
            <div id="customSpinner"></div>
            <div
              className={
                isRelatedSection === true ? "is-related-section" : "h-100"
              }
            >
              <GridComponent
                dataSource={data}
                ref={setGridRef}
                // id="Grid2"
                allowPaging
                pageSettings={pageSettings}
                allowSorting
                sortSettings={sortingOptions}
                allowFiltering={
                  allowFiltering === true && props.filterType !== "QueryBuilder"
                }
                // filterSettings={{ filterOptions }}
                {...(allowFiltering === true
                  ? {
                    filterSettings: filterSettings,
                  }
                  : {})}
                // filterSettings={{
                //     ...Browser.isDevice === false ?
                //         { showFilterBarStatus: false, mode: 'Immediate', type: 'FilterBar', }
                //         :
                //         { showFilterBarStatus: false, mode: 'Immediate', type: 'Menu', }
                // }}
                allowGrouping={false}
                allowMultiSorting={false}
                groupSettings={groupSettings}
                //allowSelection={props.allowSelection ?? true}
                // selectionSettings={{ type: 'Single' }}
                selectionSettings={selectionsettingsModel}
                allowResizing
                // autoFit
                height="100%"
                gridLines={
                  props.gridLines
                    ? props.gridLines
                    : props.isRelatedSection
                      ? "Horizontal"
                      : "Both"
                }
                // gridLines='Both'
                className={
                  (props.cssClass ? props.cssClass + " " : "") +
                  (allowFiltering
                    ? "rafSFGrid hover-table-div h-100 "
                    : "rafSFGrid hover-table-div h-100 ") +
                  (isAdaptive ? "e-device" : " ")
                }
                enableAdaptiveUI={isAdaptive}
                rowRenderingMode={isAdaptive ? "Vertical" : "Horizontal"}
                allowPdfExport
                pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
                pdfQueryCellInfo={pdfQueryCellInfo}
                allowExcelExport
                recordClick={
                  allowEditing && showEditColumn
                    ? null
                    : recordClicked.bind(this)
                }
                created={created.bind(this)}
                load={gridLoadEvent}
                dataBound={dataBound.bind(this)}
                {...(isRemote === true
                  ? { dataStateChange: dataStateChange.bind(this) }
                  : {})}
                rowSelected={rowSelected.bind(this)}
                rowDeselected={rowDeSelected.bind(this)}
                actionBegin={actionBegin.bind(this)}
                actionComplete={actionComplete.bind(this)}
                // {...(isRemote === true
                //     ? { dataStateChange: dataStateChange.bind(this) }
                //     : {})}
                {...(props.rowTemplate
                  ? { rowTemplate: props.rowTemplate }
                  : {})}
                frozenColumns={props.frozenColumns ? props.frozenColumns : null}
                statelessTemplates={["directiveTemplates"]}
              //resizeSettings={{ mode: "Auto" }}
              // enablePersistence
              //loadingIndicator={{ indicatorType: 'Shimmer' }}
              //emptyRecordTemplate={emptyRecordTemplate}
              >
                <ColumnsDirective>
                  {/* {Browser.isDevice !== true && allowSelection === true && (
                                <ColumnDirective
                                    type='checkbox'
                                    width='40px'
                                    maxWidth='40px'
                                    minWidth='40px'
                                    allowResizing={false}
                                    autoFit
                                    textAlign='Center'
                                    customAttributes={{ class: 'gridcheckboxclass' }}
                                    allowEditing={false}
                                    editTemplate={emptyTemplate}
                                />
                            )} */}
                  {getColumnsDirective(props.fields)}
                </ColumnsDirective>

                <Inject
                  services={[
                    Page,
                    Sort,
                    Filter,
                    Group,
                    Edit,
                    Toolbar,
                    Freeze,
                    Resize,
                    PdfExport,
                    ExcelExport,
                  ]}
                />
              </GridComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
//"It looks like the columns are not configured correctly. Please contact support for assistance."
export default React.memo(RAFGrid4);
